import {
  ChatFileUploadStatus,
  type UploadChatFileResponse,
} from "./../../../data/mutations/useUploadFileToConversation";
import type { InfiniteData } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import type { Conversation, ConversationMessagesResponse, Message } from "../../../types/conversation";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { sendSSEMessage } from "@/data/message";
import { conversationMessagesKeys } from "@/data/queries/useGetConversationMessages";
import { useLocation, useNavigate } from "react-router-dom";
import type { ActionStatus } from "@/types/actions";
import type { GenImage, ParseAttachment, Reference } from "@/types/trace";
import { toast } from "react-toastify";
import { useAccountUsageDialogContext } from "@/contexts/AccountUsageDialogContext/useAccountUsageDialogContext";
import useIsChatWithPagination from "./useIsChatWithPagination";
import { v4 as uuidv4 } from "uuid";
import { getSingleMessageWithRetry } from "@/data/getSingleMessage";
import { parseConcatenatedJsonObjects } from "../utils/parseConcatenatedJsonObjects";
import type { F0 } from "@/types/types";
import { ROUTES } from "@/constants/routes";
import { useGetSingleConversationDetails } from "@/data/queries/useGetSingleConversationDetails";
import { useGetUser } from "@/data/queries/useGetUser";

export const useSendChatMessage = () => {
  const {
    files,
    setFiles,
    setIsSendingMessage,
    conversationId,
    createNewConversation,
    setIsMessageError,
    isPreview,
    setMessageRecipients,
    conversationDetails,
    setResponseRequestId,
  } = useChatContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { openDialog } = useAccountUsageDialogContext();
  const withPagination = useIsChatWithPagination();
  const { pathname } = useLocation();
  const { user } = useGetUser();

  let conversationIdCopy = conversationId;

  useGetSingleConversationDetails({
    conversationId: conversationIdCopy!,
    enabled: !!conversationIdCopy && !isPreview,
  });

  const addNewMessageToCache = (newMessage: Message) => {
    queryClient.setQueryData<InfiniteData<ConversationMessagesResponse>>(
      conversationMessagesKeys.id(
        {
          conversationId: newMessage.conversationId || conversationId || "",
        },
        withPagination
      ),
      prev => {
        if (!prev) {
          return {
            pageParams: [1],
            pages: [
              {
                messages: [newMessage],
                totalMessages: 1,
              },
            ],
          };
        }

        const getNewParams = () => {
          const prevPageParams = [...prev.pageParams];
          const firstPageParam = prevPageParams[0] as number;
          prevPageParams[0] = firstPageParam + 1;

          return prevPageParams;
        };

        const conversationWithNewMessage = {
          pageParams: getNewParams(),
          pages: prev?.pages?.map((page, index) =>
            index === 0
              ? {
                  ...page,
                  messages: [newMessage, ...page.messages],
                  totalMessages: page.totalMessages + 1,
                }
              : page
          ),
        };
        return conversationWithNewMessage;
      }
    );
  };

  const updateExistingMessageFromCache = ({
    stringChunk,
    tempMessageId,
    conversationId,
    actionType,
    otherActionObject,
    genImage,
    completeMessage,
    isGenerating,
    updatedMessageId,
    references,
    isStopped,
  }: {
    stringChunk?: string;
    tempMessageId: Message["_id"];
    conversationId: Conversation["_id"];
    actionType?: ActionStatus["actionType"] | undefined;
    otherActionObject?: ParseAttachment; // or other action object like retrieve, search, webbrowse, etc.
    genImage?: GenImage;
    completeMessage?: Message;
    isGenerating: boolean;
    updatedMessageId?: Message["_id"];
    references?: Reference[];
    isStopped?: boolean;
  }) => {
    if (!conversationId) {
      throw new Error("conversationId is required to update message");
    }

    queryClient.setQueryData<InfiniteData<ConversationMessagesResponse>>(
      conversationMessagesKeys.id({ conversationId }, withPagination),
      prev => {
        if (!prev) {
          return prev;
        }

        return {
          pageParams: [...prev.pageParams],
          pages: prev?.pages?.map(page => {
            return {
              ...page,
              messages: page.messages.map(message => {
                if (message._id === tempMessageId || message._id === updatedMessageId || isStopped) {
                  return (
                    completeMessage ?? {
                      ...message,
                      _id: updatedMessageId ?? message._id,
                      text: message.text + stringChunk,
                      isGenerating,
                      trace: {
                        _id: Math.random().toString(),
                        reference: references || [],
                        meta: {
                          function: {
                            name: "",
                            response: "",
                            args: "",
                          },
                        },
                        genImage: genImage ?? {
                          status: "processing",
                          prompt: "",
                          avif: "",
                          video: "",
                        },
                        otherActionObject: otherActionObject || undefined,
                        actionType: actionType || message.trace?.actionType,
                      },
                    }
                  );
                }
                return message;
              }),
            };
          }),
        };
      }
    );
  };

  const removePendingBotMessages = ({ conversationId }: { conversationId: Conversation["_id"] }) => {
    queryClient.setQueryData<InfiniteData<ConversationMessagesResponse>>(
      conversationMessagesKeys.id({ conversationId }, withPagination),
      prev => {
        if (!prev) {
          return prev;
        }

        return {
          pageParams: [...prev.pageParams],
          pages: prev?.pages?.map(page => {
            return {
              ...page,
              messages: page.messages.filter(message => !message.isGenerating),
            };
          }),
        };
      }
    );
  };

  const handleSendSSEMessage = async (
    messageText: string,
    conversationId: Conversation["_id"],
    agents: {
      _id: string;
      name: string;
      avatar: string;
      creator?: string;
    }[],
    recipients?: {
      _id: string;
      name: string;
      avatar: string;
    }[],
    attachments?: UploadChatFileResponse[],
    audio?: { blob: Blob; duration: number }
  ) => {
    const allBotsTempMessages: Message[] = [];

    const answeringAgents = recipients?.length ? recipients : agents;

    answeringAgents.forEach(agent => {
      const botNewMessageTemp: Message = {
        _id: uuidv4(),
        conversationId,
        createdAt: new Date().toISOString(),
        seenBy: [],
        text: "",
        updatedAt: "",
        vote: null,
        bot: agent,
        isGenerating: true,
      };

      allBotsTempMessages.push(botNewMessageTemp);
      addNewMessageToCache(botNewMessageTemp);
    });

    let response: Response | null = null;
    const pins = conversationDetails ? conversationDetails.pins : [];
    try {
      response = await sendSSEMessage(
        messageText,
        conversationId,
        pins,
        recipients?.map(recipient => recipient._id),
        attachments,
        audio
      );

      setResponseRequestId(response.headers?.get("x-request-id") || null);

      if (response.status === 429) {
        openDialog("message");
        removePendingBotMessages({
          conversationId: conversationIdCopy!,
        });
        return;
      }
      if (!response.ok) {
        throw new Error(response.statusText + " Sending SSE message failed");
      }
    } catch (error) {
      removePendingBotMessages({
        conversationId,
      });
      toast.error("Failed to send message");
    }

    if (!response) {
      return;
    }

    const reader = response.body?.getReader();

    // TODO: handle reader being null (error)
    if (!reader) {
      return;
    }

    while (true) {
      const readerData = await reader.read();

      const { done, value } = readerData;

      if (done) {
        setIsSendingMessage(false);
        setResponseRequestId(null);
        return;
      }

      const decodedString = new TextDecoder("utf-8").decode(value);

      const allJsonObjects = parseConcatenatedJsonObjects(decodedString);
      let references: Reference[] | undefined = undefined;
      for (const jsonObject of allJsonObjects) {
        try {
          const parsedDecodedString = jsonObject as {
            message?: Message;
            messageId?: string;
            text?: string;
            botId: string;
            actionType?: ActionStatus["actionType"];
            actionObject?: GenImage | ParseAttachment;
            references?: Reference[];
            isStopped?: boolean;
          };

          if (!references && parsedDecodedString.references) {
            if (parsedDecodedString.references?.length > 0) {
              ({ references } = parsedDecodedString);
            }
          }

          if (parsedDecodedString?.isStopped) {
            return updateExistingMessageFromCache({
              stringChunk: "",
              tempMessageId: "",
              conversationId,
              isGenerating: false,
              isStopped: true,
            });
          }

          if (parsedDecodedString.text) {
            updateExistingMessageFromCache({
              stringChunk: parsedDecodedString.text,
              tempMessageId: allBotsTempMessages.find(bot => bot.bot?._id === parsedDecodedString.botId)?._id ?? "",
              conversationId,
              isGenerating: false,
              updatedMessageId: parsedDecodedString.messageId,
              references,
            });
          }

          if (parsedDecodedString.message) {
            void getSingleMessageWithRetry({
              conversationId,
              messageId: parsedDecodedString.message._id,
            }).then(data => {
              if (!data) {
                return;
              }

              updateExistingMessageFromCache({
                tempMessageId: allBotsTempMessages.find(bot => bot.bot?._id === parsedDecodedString.botId)?._id ?? "",
                conversationId,
                completeMessage: {
                  ...data,
                  trace: {
                    ...data.trace,
                    _id: data.trace?._id || Math.random().toString(),
                    reference: references || [],
                    meta: data.trace?.meta || {
                      function: {
                        name: "",
                        response: "",
                        args: "",
                      },
                    },
                    genImage: data.trace?.genImage || {
                      status: "processing",
                      prompt: "",
                      avif: "",
                    },
                    actionType: data.trace?.actionType,
                  },
                },
                isGenerating: false,
                updatedMessageId: parsedDecodedString.messageId,
                references,
              });
            });
          } else {
            updateExistingMessageFromCache({
              stringChunk: "",
              tempMessageId: allBotsTempMessages.find(bot => bot.bot?._id === parsedDecodedString.botId)?._id ?? "",
              conversationId,
              actionType: parsedDecodedString.actionType,
              otherActionObject: parsedDecodedString.actionObject as ParseAttachment,
              genImage: parsedDecodedString.actionObject as GenImage,
              isGenerating: false,
              updatedMessageId: parsedDecodedString.messageId,
              references,
            });
          }

          updateExistingMessageFromCache({
            stringChunk: "",
            tempMessageId: allBotsTempMessages.find(bot => bot.bot?._id === parsedDecodedString.botId)?._id ?? "",
            conversationId,
            actionType: parsedDecodedString.actionType,
            otherActionObject: parsedDecodedString.actionObject as ParseAttachment,
            genImage: parsedDecodedString.actionObject as GenImage,
            isGenerating: false,
            updatedMessageId: parsedDecodedString.messageId,
            references,
          });
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const sendMessage = async ({
    messageText,
    onSendMessageCallback,
    agents,
    recipients,
    audio,
  }: {
    messageText: string;
    onSendMessageCallback?: F0;
    agents: {
      _id: string;
      name: string;
      avatar: string;
    }[];
    recipients?: {
      _id: string;
      name: string;
      avatar: string;
    }[];
    audio?: { blob: Blob; duration: number };
  }) => {
    try {
      setIsMessageError(false);
      setIsSendingMessage(true);

      if (!conversationIdCopy) {
        const agentIds = agents.map(agent => agent._id);

        if (agentIds.length === 0) {
          throw new Error("AgentId is not defined");
        }

        const newConversationIdCopy = await createNewConversation(agentIds);

        if (newConversationIdCopy) {
          conversationIdCopy = newConversationIdCopy;
        } else {
          throw new Error("conversationId is required to send message");
        }
      }

      if (conversationIdCopy) {
        await queryClient.cancelQueries({
          queryKey: conversationMessagesKeys.id({ conversationId: conversationIdCopy }, withPagination),
        });
      }

      const attachments = files
        .filter(file => file.status === ChatFileUploadStatus.SUCCESS)
        .map(file => {
          delete file.status;
          return file;
        });

      const userNewMessageTemp: Message = {
        _id: uuidv4(),
        conversationId: conversationIdCopy || "",
        createdAt: new Date().toISOString(),
        seenBy: [],
        text: messageText,
        updatedAt: "",
        vote: null,
        attachments,
        user: user?._id,
        audio,
      };

      addNewMessageToCache(userNewMessageTemp);
      setFiles([]);
      onSendMessageCallback?.();
      await handleSendSSEMessage(messageText, conversationIdCopy, agents, recipients, attachments, audio);

      setMessageRecipients([]);

      setIsSendingMessage(false);
    } catch (error) {
      setIsMessageError(true);
      console.log(error);
    } finally {
      setIsSendingMessage(false);

      if (conversationIdCopy && !isPreview && !pathname.includes(ROUTES.conversation(conversationIdCopy))) {
        navigate(ROUTES.conversation(conversationIdCopy));
      }
    }
  };

  return { sendMessage };
};
