import { Icons } from "@/components/ui/icons";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { ChatFileUploadStatus } from "@/data/mutations/useUploadFileToConversation";

export const ChatFileList = () => {
  const { files, setFiles } = useChatContext();

  if (!files.length) {
    return null;
  }

  const removeFileFromList = (fileToRemove: string) => {
    setFiles(prevFiles => prevFiles.filter(prevFile => prevFile.filename !== fileToRemove));
  };

  return (
    <div className="flex w-full items-center gap-4 overflow-x-auto px-4 py-2">
      {files.map(file => {
        const isImageFileType = file.mimetype.split("/")[0] === "image";
        const isPdfFileType = file.mimetype === "application/pdf";

        if (isImageFileType) {
          return (
            <div
              title={file.originalname}
              {...(file.status === ChatFileUploadStatus.SUCCESS && {
                style: { backgroundImage: `url('${file?.url}')` },
              })}
              className="group relative size-16 min-w-16 rounded-md border border-neutral-300 bg-neutral-300 bg-cover bg-center"
              key={file.filename}
            >
              {file.status === ChatFileUploadStatus.LOADING && (
                <>
                  <Icons.FileImage className="size-5 text-primary-400 absolute-center" />
                  <LoadingSpinner className="absolute-center" />
                </>
              )}
              <button
                className="absolute -right-1.5 -top-1.5 grid size-5 place-items-center rounded-full border-2 border-white bg-primary-black lg:hidden lg:group-hover:grid"
                onClick={() => removeFileFromList(file.filename)}
              >
                <Icons.Close className="size-2 text-white" />
                <span className="sr-only">remove file</span>
              </button>
            </div>
          );
        }

        return (
          <div
            title={file.originalname}
            key={file.filename}
            className="group relative flex gap-2 rounded-md border border-neutral-300 p-3"
          >
            <div className="flex size-10 min-w-10 items-center justify-center rounded-[4px] bg-primary-400">
              {file.status === ChatFileUploadStatus.LOADING ? (
                <LoadingSpinner className="scale-75" />
              ) : isPdfFileType ? (
                <Icons.Pdf className="size-6 text-white [&>*]:!fill-primary-400" />
              ) : (
                <Icons.File className="size-6 text-white" />
              )}
            </div>
            <div className="flex flex-col">
              <span className="max-w-52 truncate text-base font-bold">{file.originalname}</span>
              <span className="text-xs font-medium uppercase text-neutral-400">{file.mimetype.split("/")[1]}</span>
            </div>

            <button
              className="absolute -right-1.5 -top-1.5 grid size-5 place-items-center rounded-full border-2 border-white bg-primary-black lg:hidden lg:group-hover:grid"
              onClick={() => removeFileFromList(file.filename)}
            >
              <Icons.Close className="size-2 text-white" />
              <span className="sr-only">remove file</span>
            </button>
          </div>
        );
      })}
    </div>
  );
};
