import { Icons } from "@/components/ui/icons";
import { Button } from "@/components/ui/button";
import type { UseFormReturn } from "react-hook-form";
import type { RegisterFormTypes, RegisterStep } from "@/types/user-register";
import type { Dispatch, FormEvent, SetStateAction } from "react";
import { ROUTES } from "@/constants/routes";
import { useNavigate } from "react-router";
import { APP_LINKS } from "@/constants/appLinks";
import { Input } from "@/components/ui/input";
import { get } from "lodash";

type RegisterUserAndTermsProps = {
  form: UseFormReturn<RegisterFormTypes>;
  setRegisterStep: Dispatch<SetStateAction<RegisterStep>>;
  setIsGoogleRegister: Dispatch<SetStateAction<boolean>>;
};

export const RegisterUserAndTerms = ({ form, setRegisterStep, setIsGoogleRegister }: RegisterUserAndTermsProps) => {
  const navigate = useNavigate();
  const continueRegister = async (e: FormEvent) => {
    e.preventDefault();

    await form.trigger(["name", "email"]);

    if (form.formState.errors.name || form.formState.errors.email) {
      return;
    }

    setRegisterStep("ageVerificationProcess");
  };
  const continueGoogleRegister = () => {
    setIsGoogleRegister(true);
    setRegisterStep("ageVerificationProcess");
  };

  return (
    <>
      <h2 className="mb-8 text-center text-2xl font-bold leading-9 tracking-tight lg:text-[28px]">Sign up</h2>

      <Button className="w-full gap-2" variant="dark" size="lg" onClick={continueGoogleRegister}>
        <Icons.Google />
        Continue with Google
      </Button>

      <div className="flex flex-1 items-center justify-between py-6">
        <Icons.Line />
        Or
        <Icons.Line />
      </div>

      <form onSubmit={continueRegister} className="w-full">
        <Input
          register={form.register("name")}
          error={get(form.formState.errors, "name.message")}
          placeholder="Name"
          inputClassName="h-[50px]"
        />

        <Input
          register={form.register("email")}
          error={get(form.formState.errors, "email.message")}
          placeholder="Enter email address"
          inputClassName="h-[50px] mt-4"
        />

        <Button type="submit" className="mt-8 w-full" size="lg">
          Continue with Email
        </Button>
      </form>
      <div className="text-xs font-bold leading-6">
        Got an account?{" "}
        <span className="cursor-pointer font-bold text-primary-500 underline" onClick={() => navigate(ROUTES.login)}>
          Login Now
        </span>
      </div>
      <p className="pt-8 text-left text-xs font-bold text-neutral-400">
        By clicking “Continue”, I agree to AgentX{" "}
        <a
          href={APP_LINKS.termsOfService}
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary-500 underline underline-offset-4"
        >
          Terms of Service
        </a>{" "}
        and{" "}
        <a
          href={APP_LINKS.privacy}
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary-500 underline underline-offset-4"
        >
          Privacy Policy
        </a>
        . This site is protected by reCAPTCHA.
      </p>
    </>
  );
};
