import { NavLink } from "react-router-dom";
import { ROUTES } from "@/constants/routes";
import { Icons } from "@/components/ui/icons";
import { Avatar } from "@/components/Avatar";
import { useGetUser } from "@/data/queries/useGetUser";
import { useNavigationProfileContext } from "@/contexts/NavigationProfileContext/useNavigationProfileContext";
import { MobileNav } from "@/components/navigation/MobileNav";
import { useTailwindMediaQuery } from "@/hooks/useMediaQueries";
import { SubscriptionTierButton } from "./SubscriptionTierButton";
import { useMediaQueriesContext } from "@/contexts/MediaQueriesContext/useMediaQueriesContext";
import { NavigationProfileSections } from "../Profiles/NavigationProfileSection";
import { Button } from "../ui/button";
import { useState } from "react";
import { RegisterDialog } from "../dialogs/RegisterDialog/RegisterDialog";
import { useLoginDialogContext } from "@/contexts/LoginDialogContext/useLoginDialogContext";

export const TopBarNav = ({ isGuestUser, isLoadingUser }: { isGuestUser: boolean; isLoadingUser?: boolean }) => {
  const { user } = useGetUser({ enabled: !isGuestUser });
  const { setNavigationProfileOpen } = useNavigationProfileContext();
  const { isMobile } = useMediaQueriesContext();
  const isNotPhone = useTailwindMediaQuery("sm");
  const { showLoginDialog } = useLoginDialogContext();

  const [isRegisterDialogOpen, setIsRegisterDialogOpen] = useState(false);

  const getNavContent = () => {
    if (isLoadingUser) {
      return null;
    }

    return isGuestUser ? (
      <div className="flex items-center gap-2">
        <Button roundedType="rounded" variant="secondary" onClick={showLoginDialog}>
          Log in
        </Button>
        <Button roundedType="rounded" variant="primary" onClick={() => setIsRegisterDialogOpen(true)}>
          Sign up
        </Button>
      </div>
    ) : (
      <div className="flex items-center gap-6">
        {user?.subscription.tier !== undefined && !isMobile && <SubscriptionTierButton tier={user.subscription.tier} />}
        <Avatar
          src={user?.avatar}
          name={user?.name}
          onClick={() =>
            setNavigationProfileOpen({
              open: true,
              section: isNotPhone ? NavigationProfileSections.MY_ACCOUNT : null,
            })
          }
          className="size-8 cursor-pointer rounded-full lg:size-10"
          size="custom"
        />
      </div>
    );
  };

  return (
    <>
      <nav className="fixed z-10 flex w-full items-center justify-between overflow-hidden bg-transparent px-6 py-5">
        <MobileNav isGuestUser={isGuestUser} />
        <NavLink to={isGuestUser ? ROUTES.community : ROUTES.workspace}>
          <Icons.AgentLogo className="max-h-8 w-[100px] cursor-pointer text-primary-black lg:max-h-10" />
        </NavLink>

        {getNavContent()}
      </nav>

      <RegisterDialog isDialogOpen={isRegisterDialogOpen} setShowDialog={setIsRegisterDialogOpen} />
    </>
  );
};
