import type { AgentBuildInFunction } from "@/types/agent";
import { agentBuildInFunctions } from "@/types/agent";
import { LoadingSpinner } from "../../ui/loading-spinner";
import type { GenImage, ParseAttachment } from "@/types/trace";

type ActionLoaderProps = {
  action: AgentBuildInFunction;
  genImage?: GenImage;
  actionObject?: ParseAttachment; // or other action object like retrieve, search, webbrowse, etc.
};

export const ActionLoader = ({ action, actionObject }: ActionLoaderProps) => {
  switch (action) {
    case agentBuildInFunctions.IMAGE_GENERATE || agentBuildInFunctions.IMAGE_GENERATE_FLUX:
      return (
        <div className="m-4 flex items-center space-x-2">
          <LoadingSpinner />
          <p className="text-sm font-medium leading-[170%] text-primary-400">Creating image...</p>
        </div>
      );
    case agentBuildInFunctions.VIDEO_GENERATE:
      return (
        <div className="m-4 flex items-center space-x-2">
          <LoadingSpinner />
          <p className="text-sm font-medium leading-[170%] text-primary-400">Creating video...</p>
        </div>
      );
    case agentBuildInFunctions.BROWSE_WEB:
      return (
        <>
          <LoadingSpinner />
          {agentBuildInFunctions.BROWSE_WEB}
        </>
      );
    case agentBuildInFunctions.SEARCH_ENGINE:
      return (
        <>
          <LoadingSpinner />
          {agentBuildInFunctions.SEARCH_ENGINE}
        </>
      );
    case agentBuildInFunctions.EVENT_SCHEDULER:
      return (
        <>
          <LoadingSpinner />
          {agentBuildInFunctions.EVENT_SCHEDULER}
        </>
      );
    case agentBuildInFunctions.TEXT_EXTRACTION:
      return (
        <>
          <LoadingSpinner />
          {agentBuildInFunctions.TEXT_EXTRACTION}
        </>
      );
    case agentBuildInFunctions.ATTACHMENT:
      if (actionObject?.status === "processing") {
        return (
          <div className="m-4 flex items-center space-x-2">
            <LoadingSpinner />
            <p className="text-sm font-medium leading-[170%] text-primary-400">
              🔎 {actionObject.attachment_index}. {actionObject.attachment_name} - {actionObject.step} /{" "}
              {actionObject.total_step}{" "}
            </p>
          </div>
        );
      } else {
        return (
          <div className="m-4 flex items-center space-x-2">
            <LoadingSpinner />
            <p className="text-sm font-medium leading-[170%] text-primary-400">{agentBuildInFunctions.ATTACHMENT}</p>
          </div>
        );
      }
    default:
      return <></>;
  }
};
