import { useState } from "react";
import type { Tool } from "@/types/tools";
import { Icons } from "@/components/ui/icons";
import Editor from "@monaco-editor/react";
import { Controller, type UseFormReturn } from "react-hook-form";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useGetSingleToolLogs } from "@/data/queries/useGetSingleToolLogs";
import { Button } from "@/components/ui/button";
import { useAgentFormContext } from "@/components/AgentForm/hooks/useAgentFormContext";

type CodeEditorProps = {
  form: UseFormReturn<Tool>;
};

const defaultCode = `def run(args_json: dict):
    # To get the value of a parameter, use args_json['parameter_name']
    # e.g. my_parameter = args_json.get('my_parameter')
    # json, requests are automatically imported
    # < Write your code here >
    return json.dumps({'response': 'change me', 'success': True})
`;

type CodeOrLog = "Code" | "Logs";

export const CodeEditor = ({ form }: CodeEditorProps) => {
  const agentForm = useAgentFormContext();
  const [activeCodeOrLog, setActiveCodeOrLog] = useState<CodeOrLog>("Code");
  const { data: logs } = useGetSingleToolLogs({
    agentId: agentForm.getValues("_id") || "",
    toolId: form.getValues("_id") || "",
  });

  return (
    <div className="min-h-[600px] overflow-hidden rounded-md border border-neutral-300">
      <div className="w-full border-b border-neutral-300 p-3 text-sm font-medium">Code editor</div>
      <Tabs value={activeCodeOrLog} className="w-full space-y-4">
        <TabsList className="w-full bg-neutral-100 p-1">
          {["Code", "Logs"].map(tab => (
            <TabsTrigger
              key={tab}
              value={tab}
              onClick={() => setActiveCodeOrLog(tab as CodeOrLog)}
              className="flex-1 text-neutral-600 data-[state=active]:text-neutral-750"
            >
              {tab}
            </TabsTrigger>
          ))}
        </TabsList>
      </Tabs>
      {activeCodeOrLog === "Code" ? (
        <Controller
          control={form.control}
          name="toolFunction.code"
          render={({ field: { value, onChange } }) => (
            <Editor height="50vh" defaultLanguage="python" defaultValue={value || defaultCode} onChange={onChange} />
          )}
        />
      ) : (
        <div className="h-[85%] overflow-auto p-3">
          <div>
            {logs?.urlList.map((log, index) => (
              <div key={`log_${index}`}>
                <Button variant="link" type="button" className="p-0 text-primary-500">
                  <Icons.FileDownload />
                  <a className="pl-2" href={`${log}`}>
                    {log.split("/").pop()}
                  </a>
                </Button>
              </div>
            ))}
          </div>
          {logs?.urlList.length === 0 && <div className="text-neutral-400">No logs available</div>}
        </div>
      )}
    </div>
  );
};
