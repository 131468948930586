import { uniqueNamesGenerator, names } from "unique-names-generator";
import { Button } from "@/components/ui/button";
import { useAgentFormContext } from "../../../hooks/useAgentFormContext";
import { Input } from "@/components/ui/input";
import { get } from "lodash";

export const AgentName = () => {
  const form = useAgentFormContext();

  const {
    register,
    formState: { errors },
  } = form;

  const handleRandomName = () => {
    const randomName = uniqueNamesGenerator({ dictionaries: [names] });
    form.setValue("name", randomName, { shouldDirty: true });
  };

  return (
    <div className="flex flex-col gap-3 sm:flex-row">
      <form className="relative flex w-full ">
        <Input
          label="Agent name"
          register={register("name")}
          error={get(errors, "name.message")}
          inputClassName="flex-1 pr-16 sm:pr-16"
          placeholder="What is your agent's name?"
        >
          <Button
            type="button"
            variant="secondary"
            className="absolute right-0.5 top-1/2 flex -translate-y-1/2 px-1 py-2 text-xs"
            onClick={handleRandomName}
            size="custom"
          >
            Random
          </Button>
        </Input>
      </form>

      <Input
        label="Creator name"
        register={register("company")}
        inputClassName="flex-1"
        placeholder="Can be a name of your company"
        error={get(errors, "company.message")}
      />
    </div>
  );
};
