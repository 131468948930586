import { useMutation } from "@tanstack/react-query";
import { apiPaths } from "../apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "../initAxios";

const stopSSEResponse = async (id: string) => {
  const { data } = await restClient.post<string>(apiPaths.stopSSEResponse(id));
  return data;
};

export const useStopSSEResponse = () => {
  return useMutation<string, AxiosError<{ message: string }>, string>({
    mutationFn: stopSSEResponse,
  });
};
