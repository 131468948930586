import { WorkspaceMyAgents } from "@/pages/Workspace/components/WorkspaceMyAgents";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { FetchNextPageTrigger } from "@/components/Chat/components/FetchNextPageTrigger";
import { useGetUser } from "@/data/queries/useGetUser";
import { useGetWorkspaceAgents } from "@/data/queries/workspace/useGetWorkspaceAgents";
import { useGetWorkspaceDetails } from "@/data/queries/workspace/useGetWorkspaceDetails";
import { CustomerSpaceDetails } from "@/pages/Workspace/components/CustomerSpace/CustomerSpaceDetails";

export const CustomerSpace = () => {
  const { user } = useGetUser();
  const {
    data: agents,
    isPending: isAgentsWithConfigPending,
    isSuccess: isAgentsWithConfigSuccess,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetWorkspaceAgents();
  const { data: workspaceDetails } = useGetWorkspaceDetails();

  const isCustomer = user?.customer;

  if (!isCustomer) {
    return null;
  }

  return (
    <>
      <CustomerSpaceDetails workspaceDetails={workspaceDetails} />

      <WorkspaceMyAgents
        agents={agents ?? []}
        isLoading={isAgentsWithConfigPending}
        isSuccess={isAgentsWithConfigSuccess}
        canEdit={user?.customer ? workspaceDetails?.isAdmin ?? false : true}
      />

      {isFetchingNextPage && <LoadingSpinner className="my-5 flex items-center justify-center" />}

      {hasNextPage && <FetchNextPageTrigger fetchNextPage={fetchNextPage} />}
    </>
  );
};
