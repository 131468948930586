import { useQuery } from "@tanstack/react-query";
import { restClient } from "../../initAxios";

export const googleAccessToken = {
  all: ["googleAccessToken"],
};

export const getGoogleAccessToken = async () => {
  const { data } = await restClient.get<{ accessToken: string }>(`/externalService/googleAccessToken`);
  return data;
};

export const useGetGoogleAccessToken = () => {
  const query = useQuery({
    queryKey: googleAccessToken.all,
    queryFn: getGoogleAccessToken,
  });

  return query;
};
