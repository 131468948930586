import { Outlet } from "react-router-dom";
import { useGetUser } from "@/data/queries/useGetUser";
import MainLayout from "../Layouts/MainLayout";

export const GuestRoutes = () => {
  const token = localStorage.getItem("token");
  const { user, isLoading } = useGetUser({ enabled: !!token });

  const isGuestUser = !user;

  window.isPanel = true;

  return (
    <MainLayout isGuestUser={isGuestUser} isLoadingUser={isLoading}>
      <Outlet context={{ isGuestUser }} />
    </MainLayout>
  );
};
