import { LoginDialog } from "@/components/dialogs/LoginDialog/LoginDialog";
import type { LoginDialogContextProps } from "./LoginDialogContext";
import { LoginDialogContext } from "./LoginDialogContext";
import { useState } from "react";

type LoginDialogProviderProps = {
  children: React.ReactNode;
};

export const LoginDialogProvider = ({ children }: LoginDialogProviderProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const value: LoginDialogContextProps = {
    showLoginDialog: () => setIsDialogOpen(true),
  };

  return (
    <LoginDialogContext.Provider value={value}>
      {children}
      <LoginDialog isDialogOpen={isDialogOpen} setShowDialog={setIsDialogOpen} />
    </LoginDialogContext.Provider>
  );
};
