import { MessageCloud } from "./MessageCloud";
import { MessageError } from "./MessageError";
import { PredefinedQuestions } from "./PredefinedQuestions";
import { differenceInMinutes, format } from "date-fns";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { Fragment, useState } from "react";
import { useGetConversationMessages } from "@/data/queries/useGetConversationMessages";
import type { ChatAgent } from "@/types/conversation";
import type { EmbedKnowledges, FullAgent } from "@/types/agent";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import useScrollToMessage from "../hooks/useScrollToMessage";
import { domElementIds } from "@/types/dom-element-ids";
import { FetchNextPageTrigger } from "./FetchNextPageTrigger";
import { DocumentInfoPreview } from "@/components/AgentForm/components/Knowledge/components/DocumentInfoPreview";

type ChatContentProps = {
  agents: ChatAgent[];
};

export const ChatContent = ({ agents }: ChatContentProps) => {
  const { isSendingMessage, isGroupChat, isMessageError, conversationId } = useChatContext();
  const [documentInPreview, setDocumentInPreview] = useState<EmbedKnowledges | null>(null);
  const [docPreviewPage, setDocPreviewPage] = useState<number | null>(null);

  const {
    data: conversationData,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useGetConversationMessages({
    conversationId,
  });

  useScrollToMessage({ enabled: !!conversationData });

  const isFirstPage = conversationData?.messages.length === conversationData?.totalMessages;

  const canUsePredefinedQuestions = !isGroupChat;
  const arePredefinedQuestionsDisabled = isLoading || isSendingMessage;
  const isChatEmpty = conversationData?.totalMessages === 0 || !conversationData;

  const getAgentsGreetings = (agent: ChatAgent) => {
    return agent.definedActions?.greeting.map(greeting => greeting.message).reverse();
  };

  if (isLoading) {
    return (
      <div className="grid size-full place-items-center">
        <LoadingSpinner />
      </div>
    );
  }

  const canShowGreetingMessages = isFirstPage;

  const botNames = [
    ...new Set(
      conversationData?.messages.filter(message => "bot" in message).map(message => message?.bot?.name ?? "") ?? []
    ),
  ];

  return (
    <div className="flex size-full flex-col-reverse overflow-y-auto" id={domElementIds.CHAT_CONTENT_CONTAINER}>
      <div id={domElementIds.CHAT_CONTENT_SCROLL_TO} />

      {canUsePredefinedQuestions && isChatEmpty && (
        <PredefinedQuestions agent={agents[0] as FullAgent} disabled={arePredefinedQuestionsDisabled} />
      )}

      {isMessageError && <MessageError />}

      {conversationData?.messages.map((message, index, messages) => {
        const currentMessageTime = new Date(message.createdAt);
        const prevMessageTime = index > 0 && new Date(messages[index + 1]?.createdAt);
        const timeDiff = prevMessageTime && differenceInMinutes(currentMessageTime, prevMessageTime);
        const isFirstMessage = index === conversationData.totalMessages - 1;

        return (
          <Fragment key={`${message.conversationId}-message-${index}`}>
            <MessageCloud
              message={message.text}
              isBotMessage={"bot" in message}
              key={message._id + message.text}
              messageId={message._id}
              conversationId={message.conversationId}
              trace={message.trace}
              vote={message.vote}
              messageIndex={index}
              createdAt={message.createdAt}
              isBookmark={message.isBookmark}
              agent={message.bot}
              botNames={botNames}
              isGeneratingBotResponse={message.isGenerating}
              attachments={message.attachments}
              messageUser={message.user}
              audio={message?.audio}
              setDocumentInPreview={setDocumentInPreview}
              setDocPreviewPage={setDocPreviewPage}
            />
            {index === conversationData?.messages.length - 1 || (timeDiff && timeDiff > 5) ? (
              <div className="my-1 flex justify-center text-xs text-gray-400">
                {format(currentMessageTime, "dd MMM yyyy HH:mm")}
              </div>
            ) : null}

            {isFirstMessage && canUsePredefinedQuestions && (
              <PredefinedQuestions agent={agents[0] as FullAgent} disabled={arePredefinedQuestionsDisabled} />
            )}
          </Fragment>
        );
      })}

      {agents.map(agent => {
        const showGreetingMessages = canShowGreetingMessages && !!agent.definedActions?.greeting.length;

        if (!agent || !showGreetingMessages) {
          return;
        }

        return getAgentsGreetings(agent)?.map(greeting => (
          <MessageCloud
            message={greeting}
            isBotMessage
            key={greeting}
            vote={null}
            isBookmark={false}
            agent={agent}
            botNames={botNames}
            setDocumentInPreview={setDocumentInPreview}
            setDocPreviewPage={setDocPreviewPage}
          />
        ));
      })}

      {isFetchingNextPage && <LoadingSpinner className="mx-auto my-5" />}

      {hasNextPage && <FetchNextPageTrigger fetchNextPage={fetchNextPage} />}
      <DocumentInfoPreview
        setDocumentInPreview={setDocumentInPreview}
        documentInPreview={documentInPreview}
        docPreviewPage={docPreviewPage}
        setDocPreviewPage={setDocPreviewPage}
      />
    </div>
  );
};
