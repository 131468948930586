import type { Agent, FullAgent } from "@/types/agent";
import WorkspaceAgentTile from "./WorkspaceAgentTile";
import { useState } from "react";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import NotFound from "@/assets/images/NotFound.svg";
import { DeleteAgentDialog } from "@/components/dialogs/DeleteAgentDialog/DeleteAgentDialog";
import { DuplicateAgentDialog } from "@/components/dialogs/DuplicateAgentDialog/DuplicateAgentDialog";
import { useCreateNewAgent } from "@/hooks/useCreateNewAgent";

export const WorkspaceMyAgents = ({
  agents,
  isLoading,
  isSuccess,
  canEdit,
}: {
  agents: FullAgent[];
  isLoading: boolean;
  isSuccess: boolean;
  canEdit: boolean;
}) => {
  const [dialogAgentId, setDialogAgentId] = useState<Agent["_id"] | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDuplicateDialogOpen, setIsDuplicateDialogOpen] = useState(false);
  const { createNewAgentNavigation } = useCreateNewAgent();

  if (isLoading) {
    return <LoadingSpinner className="flex h-32 w-full items-center justify-center" />;
  }

  const showAgents = isSuccess && agents?.length;

  if (!showAgents) {
    return (
      <div className="flex w-full flex-col items-center justify-center gap-2">
        <img src={NotFound} />
        <p className="text-sm text-neutral-500">
          No agents found,{" "}
          <a className="text-primary-400 hover:cursor-pointer hover:underline" onClick={createNewAgentNavigation}>
            create
          </a>{" "}
          now
        </p>
      </div>
    );
  }

  return (
    <>
      <div>
        <div className="relative mb-10 grid auto-rows-fr grid-cols-[repeat(auto-fill,minmax(230px,1fr))] content-start gap-6">
          {agents.map(agent => (
            <WorkspaceAgentTile
              key={agent._id}
              agent={agent}
              displayOptions={canEdit}
              onDeleteAgentClick={() => {
                setDialogAgentId(agent._id);
                setIsDeleteDialogOpen(true);
              }}
              /*onDuplicateAgentClick={() => {
                setDialogAgentId(agent._id);
                setIsDuplicateDialogOpen(true);
              }}*/
            />
          ))}
        </div>
      </div>

      <DeleteAgentDialog
        agentId={dialogAgentId}
        setAgentId={setDialogAgentId}
        isDialogOpen={isDeleteDialogOpen}
        setShowDialog={setIsDeleteDialogOpen}
      />

      <DuplicateAgentDialog
        agentId={dialogAgentId}
        setAgentId={setDialogAgentId}
        isDialogOpen={isDuplicateDialogOpen}
        setShowDialog={setIsDuplicateDialogOpen}
      />
    </>
  );
};
