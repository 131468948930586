import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { EmbedKnowledges } from "@/types/agent";
import type { EventFor, F1 } from "@/types/types";
import { isValidUrl, normalizeUrl } from "@/utils/isValidUrl";
import { useState } from "react";

type OnlineInfoSitemapProps = {
  appendWeb: F1<EmbedKnowledges | EmbedKnowledges[]>;
  webData: EmbedKnowledges[];
  disabled: boolean;
};

export const OnlineInfoSitemap = ({ appendWeb, webData, disabled }: OnlineInfoSitemapProps) => {
  const [isSitemapLoading, setIsSitemapLoading] = useState(false);
  const [newSitemap, setNewSitemap] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const addSitemap = async (e: EventFor<"form", "onSubmit">) => {
    e.preventDefault();
    if (!newSitemap.includes(".xml") && !newSitemap.includes(".XML")) {
      return setErrorMessage("Sitemap address must end with .xml");
    }

    const urlWithHttps =
      newSitemap.includes("https://") || newSitemap.includes("http://") ? newSitemap : `https://${newSitemap}`;
    const isValid = isValidUrl(urlWithHttps);
    if (!isValid) {
      return setErrorMessage("Invalid URL");
    }

    /*if (webData.some(web => normalizeUrl(web.source) === normalizeUrl(urlWithHttps))) {
      return setErrorMessage("This link already exists");
    }*/

    try {
      setIsSitemapLoading(true);
      const { data } = await restClient.post<string[]>(apiPaths.readSitemap, { url: urlWithHttps });
      const siteMapLinksObjects = data.map((link: string) => ({ source: link })) as EmbedKnowledges[];
      // Filter out the links that are already in webData
      const newLinks = siteMapLinksObjects.filter(
        newLink => !webData.some(existingLink => normalizeUrl(existingLink?.source) === normalizeUrl(newLink?.source))
      );

      appendWeb(newLinks);
    } catch (error) {
      console.log(error);
      return setErrorMessage("Invalid sitemap address");
    } finally {
      setIsSitemapLoading(false);
    }

    setNewSitemap("");
    setErrorMessage("");
  };

  return (
    <form onSubmit={addSitemap} className="flex flex-col items-end gap-2 lg:flex-row">
      <Input
        label="Sitemap address"
        value={newSitemap}
        name="sitemapAddress"
        onChange={e => setNewSitemap(e.target.value)}
        error={errorMessage}
        placeholder="https://www.agentx.so/sitemap.xml"
        disabled={disabled}
      />
      <Button
        type="submit"
        disabled={!newSitemap.trim().length || disabled}
        loading={isSitemapLoading}
        className="w-full sm:w-fit"
      >
        Add link
      </Button>
    </form>
  );
};
