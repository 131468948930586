import { Icons } from "@/components/ui/icons";
import { allowedFileFormats } from "@/utils/fileUploads";
import { cn } from "@/lib/utils";

export const FileIcon = ({ fileName, className }: { fileName: string; className?: string }) => {
  const extension = fileName?.slice(fileName.lastIndexOf("."));
  const commonProps = { className: cn("h-5 w-5 text-primary-500", className) };
  if (!extension) {
    return <Icons.FileGeneral {...commonProps} />;
  }
  const FoundIconComponent = allowedFileFormats.find(format => format?.extension === extension)?.icon;

  if (FoundIconComponent) {
    return <FoundIconComponent {...commonProps} />;
  }
  return <Icons.FileGeneral {...commonProps} />;
};
