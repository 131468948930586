import { TopBarNav } from "../navigation/TopBarNav";
import { SideBarNav } from "../navigation/SideBarNav";
import { useMediaQueriesContext } from "@/contexts/MediaQueriesContext/useMediaQueriesContext";

const MainLayout = ({
  children,
  isGuestUser,
  isLoadingUser,
}: {
  children: React.ReactNode;
  isGuestUser: boolean;
  isLoadingUser?: boolean;
}) => {
  const { isMobile } = useMediaQueriesContext();

  return (
    <div className="h-dvh w-dvw">
      <TopBarNav isGuestUser={isGuestUser} isLoadingUser={isLoadingUser} />
      <SideBarNav isGuestUser={isGuestUser} />
      <div className="flex size-full overflow-hidden bg-gradient-to-b from-violet-50 to-stone-50 pt-[72px] lg:pl-[48px] lg:pt-20 ">
        <div
          className="mx-auto flex max-w-screen-md flex-1 rounded-t-[20px] border-l-[1.5px] border-t-[1.5px] border-neutral-200 bg-white lg:ml-[24px] lg:max-w-none lg:rounded-tr-none"
          style={{
            maxWidth: isMobile ? "100dvw" : "calc(100dvw - 48px - 24px)",
            maxHeight: isMobile ? "100dvh" : "calc(100dvh - 72px)",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
