import { ROUTES } from "@/constants/routes";
import { NavLink, useNavigate } from "react-router-dom";
import { Icons } from "../ui/icons";
import { AgentHistoryNav } from "@/components/navigation/AgentHistoryNav";
import { cn } from "@/lib/utils";
import { useMediaQueriesContext } from "@/contexts/MediaQueriesContext/useMediaQueriesContext";
import { SideBarNavItem } from "./SideBarNavItem";
import { Button } from "../ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useState } from "react";
import MultiAgentsChatDialog from "../dialogs/MultiAgentsChatDialog/MultiAgentsChatDialog";
import { getNewConversationRoute } from "@/utils/getNewConversationRoute";
import { useGetWorkspaceDetails } from "@/data/queries/workspace/useGetWorkspaceDetails";
import { useGetUser } from "@/data/queries/useGetUser";
import { useLoginDialogContext } from "@/contexts/LoginDialogContext/useLoginDialogContext";
import { InviteTeamMemberNavBtn } from "@/components/navigation/InviteTeamMemberNavBtn";

export const SideBarNav = ({
  mobileNav = false,
  onCloseMobileNav,
  isGuestUser,
}: {
  mobileNav?: boolean;
  onCloseMobileNav?: () => void;
  isGuestUser: boolean;
}) => {
  const { data: workspaceDetails } = useGetWorkspaceDetails();
  const { data: user } = useGetUser({ enabled: !isGuestUser });
  const { isMobile } = useMediaQueriesContext();
  const navigate = useNavigate();
  const { showLoginDialog } = useLoginDialogContext();

  const isWorkspaceAdmin = workspaceDetails?.isAdmin;
  const isStatsDisabled = !!(!isWorkspaceAdmin && user?.customer);

  const [isMultiAgentsChatDialogOpen, setIsMultiAgentsChatDialogOpen] = useState(false);

  if (!mobileNav && isMobile) {
    return null;
  }

  const navigationItems: {
    name: string;
    icon: (typeof Icons)[keyof typeof Icons];
    to: string;
    needToLogIn?: boolean;
    hidden?: boolean;
  }[] = [
    { name: "Space", icon: Icons.Galaxy, to: ROUTES.community },
    { name: "Workspace", icon: Icons.Agent, to: ROUTES.workspace, needToLogIn: isGuestUser },
    { name: "Stats", icon: Icons.Stats, to: ROUTES.stats, hidden: isStatsDisabled, needToLogIn: isGuestUser },
    { name: "Help", icon: Icons.Info, to: ROUTES.help, needToLogIn: isGuestUser },
  ];

  const handleCreateMultiAgentsChat = ({ agentIds }: { agentIds: string[] }) => {
    navigate(getNewConversationRoute(agentIds));
    setIsMultiAgentsChatDialogOpen(false);
  };

  return (
    <>
      <div className="flex flex-col gap-4 bg-transparent pt-12 text-xs font-medium leading-5 lg:fixed lg:z-[11] lg:ml-[14px] lg:h-screen lg:w-10 lg:pb-6 lg:pt-20">
        {navigationItems
          .filter(item => !item?.hidden)
          .map((item, index) => (
            <SideBarNavItem isMobile={isMobile} key={item.name + index}>
              {item.needToLogIn ? (
                <button
                  className={cn(
                    "grid w-full grid-cols-[16px_1fr] items-center gap-3 whitespace-nowrap bg-white px-3 py-[9px] text-primary-black"
                  )}
                  onClick={showLoginDialog}
                >
                  <item.icon className="size-4" />
                  <span>{item.name}</span>
                </button>
              ) : (
                <NavLink to={item.to}>
                  {({ isActive }) => (
                    <div
                      className={cn(
                        "grid grid-cols-[16px_1fr] items-center gap-3 whitespace-nowrap bg-white px-3 py-[9px] text-primary-black",
                        {
                          "bg-primary-500 text-white": isActive,
                          "cursor-not-allowed bg-white text-neutral-400": item.to === "#",
                        }
                      )}
                    >
                      <item.icon className="size-4" />
                      <span>{item.name}</span>
                    </div>
                  )}
                </NavLink>
              )}
            </SideBarNavItem>
          ))}
        {!isGuestUser && <AgentHistoryNav onCloseMobileNav={onCloseMobileNav} />}

        {!isMobile && (
          <div className="mt-auto flex flex-col gap-3">
            <InviteTeamMemberNavBtn />
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button className="h-10 w-10 rounded-full bg-primary-100 p-0" variant="ghost">
                  <Icons.Plus className="h-6 w-6 text-primary-400" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent side="right" align="end" sideOffset={16} className="flex flex-col gap-1 p-1.5">
                <DropdownMenuItem
                  className="flex cursor-pointer items-center gap-4 rounded-[4px] py-3 pl-4 text-sm font-semibold text-neutral-750"
                  onClick={() => {
                    if (isGuestUser) {
                      showLoginDialog();
                    } else {
                      navigate(ROUTES.createAgent);
                    }
                  }}
                >
                  <Icons.Bot /> Create Agent
                </DropdownMenuItem>

                <DropdownMenuItem
                  className="flex cursor-pointer items-center gap-4 rounded-[4px] py-3 pl-4 text-sm font-semibold text-neutral-750"
                  onClick={() => {
                    if (isGuestUser) {
                      showLoginDialog();
                    } else {
                      setIsMultiAgentsChatDialogOpen(true);
                    }
                  }}
                >
                  <Icons.AddGroup /> Multi Agents Chat
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )}
      </div>

      <MultiAgentsChatDialog
        isOpen={isMultiAgentsChatDialogOpen}
        setIsOpen={setIsMultiAgentsChatDialogOpen}
        onSubmit={agentIds => handleCreateMultiAgentsChat({ agentIds })}
        headerText="Select Agents to Create Group Chat"
      />
    </>
  );
};
