import { Select, SelectContent, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";

type Props = {
  addButtonComponent: () => void;
  setNewButtonType: (value: "PHONE_NUMBER" | "URL") => void;
  setNewButtonText: (value: string) => void;
  setNewButtonValue: (value: string) => void;
  newButtonType: "PHONE_NUMBER" | "URL";
  newButtonText: string;
  newButtonValue: string;
};

export const WhatsAppTemplateButtonDropdown = (props: Props) => {
  return (
    <Select>
      <SelectTrigger
        id="emailModel"
        className="relative flex h-8 w-[auto] w-fit gap-2 rounded-full border border-cyan-500 p-2 px-4 py-2 text-sm font-semibold text-cyan-500 shadow-sm"
      >
        <SelectValue placeholder="Add a new button to the template"></SelectValue>
      </SelectTrigger>
      <SelectContent>
        <div className="flex flex-col gap-1 p-2">
          <Label htmlFor="buttonType">Button Type</Label>
          <select
            id="buttonType"
            className="rounded-md border border-gray-300 p-2"
            value={props.newButtonType}
            onChange={e => props.setNewButtonType(e.target.value as "PHONE_NUMBER" | "URL")}
          >
            <option value="URL">URL</option>
            <option value="PHONE_NUMBER">Phone Number</option>
          </select>
          <Label htmlFor="buttonText">Button Text</Label>
          <Input
            id="buttonText"
            placeholder="Button Text"
            value={props.newButtonText}
            onChange={e => props.setNewButtonText(e.target.value)}
          />
          <Label htmlFor="buttonValue">{props.newButtonType === "URL" ? "URL" : "Phone Number"}</Label>
          <Input
            id="buttonValue"
            placeholder={props.newButtonType === "URL" ? "https://example.com" : "+1234567890"}
            value={props.newButtonValue}
            onChange={e => props.setNewButtonValue(e.target.value)}
          />
          <Button variant="secondary" onClick={props.addButtonComponent} className="mt-2">
            Create
          </Button>
        </div>
      </SelectContent>
    </Select>
  );
};
