import { Button } from "@/components/ui/button";
import { Icons } from "@/components/ui/icons";
import { Input } from "@/components/ui/input";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PublishAgentDialog } from "../../../components/dialogs/PublishAgentDialog/PublishAgentDialog";
import { useIsGuestUser } from "@/hooks/useIsGuestUser";
import { useLoginDialogContext } from "@/contexts/LoginDialogContext/useLoginDialogContext";

export const PublishInput = () => {
  const { isGuestUser } = useIsGuestUser();
  const { showLoginDialog } = useLoginDialogContext();

  const [searchParams, setSearchParams] = useSearchParams({});
  const [isPostAgentDialogOpen, setIsPostAgentDialogOpen] = useState(false);

  return (
    <div className="mx-auto flex w-full max-w-[375px] flex-col-reverse justify-center gap-4 sm:max-w-none md:w-1/2 lg:flex-row">
      <Button
        onClick={() => {
          if (isGuestUser) {
            showLoginDialog();
            return;
          }
          setIsPostAgentDialogOpen(true);
        }}
        className="flex h-10 max-h-[40px] gap-1 rounded-[63px] bg-gradient-to-r from-violet-300 via-violet-300 to-orange-200 pl-4 pr-6 text-sm text-primary-500"
      >
        <Icons.Plus className="p-[3px]" /> Publish
      </Button>
      <div className="relative flex-1">
        <Input
          value={searchParams.get("q") ?? ""}
          onChange={e => {
            setSearchParams(
              prev => {
                if (e.target.value === "") {
                  prev.delete("q");
                  return prev;
                }
                prev.set("q", e.target.value);
                return prev;
              },
              { replace: true }
            );
          }}
          placeholder="Search everything in community"
          inputClassName="my-0 h-10 w-full rounded-full border border-primary-500 pl-10 sm:pl-10"
          handleClear={() => {
            setSearchParams(
              prev => {
                prev.delete("q");
                return prev;
              },
              { replace: true }
            );
          }}
        />
        <Icons.Search className="absolute left-4 top-1/2 -translate-y-1/2 transform" />
      </div>
      {isPostAgentDialogOpen && (
        <PublishAgentDialog isDialogOpen={isPostAgentDialogOpen} setShowDialog={setIsPostAgentDialogOpen} />
      )}
    </div>
  );
};
