import { Avatar } from "@/components/Avatar";
import { Icons } from "@/components/ui/icons";
import { ROUTES } from "@/constants/routes";
import { useLoginDialogContext } from "@/contexts/LoginDialogContext/useLoginDialogContext";
import type { User } from "@/types/user";
import { useNavigate } from "react-router";

type AgentTileCreatorInfoProps = {
  agent: {
    creatorUser?: {
      _id: User["_id"];
      name: User["name"];
      avatar: User["avatar"];
      email: User["email"];
    };
    likedByUser?: boolean;
    likes?: number;
  };
  onLikeAgentClick: () => void;
  seeCreatorProfileDisabled?: boolean;
  hideLikesCount?: boolean;
};

const AgentTileCreatorInfo = ({
  agent,
  hideLikesCount,
  onLikeAgentClick,
  seeCreatorProfileDisabled,
}: AgentTileCreatorInfoProps) => {
  const navigate = useNavigate();
  const { showLoginDialog } = useLoginDialogContext();

  return (
    <div className="flex items-center justify-between gap-2 pt-2">
      {!!agent.creatorUser && (
        <>
          <Avatar
            name={agent.creatorUser.name}
            src={agent.creatorUser?.avatar}
            size="custom"
            className="size-5 cursor-pointer"
            onClick={() => {
              if (seeCreatorProfileDisabled) {
                showLoginDialog();
                return;
              }
              navigate(ROUTES.communityProfile(agent.creatorUser?._id || ""));
            }}
          />
          <div className="flex flex-col">
            <div
              className="cursor-pointer break-all text-xs"
              onClick={() => {
                if (seeCreatorProfileDisabled) {
                  showLoginDialog();
                  return;
                }
                navigate(ROUTES.communityProfile(agent.creatorUser?._id || ""));
              }}
            >
              @{agent.creatorUser.name}
            </div>
          </div>
        </>
      )}

      {hideLikesCount ? (
        <div className="ml-auto" />
      ) : (
        <div className="ml-auto flex items-center justify-center gap-1 text-neutral-400">
          <button onClick={onLikeAgentClick}>
            {agent.likedByUser ? (
              <Icons.HeartFilled className="text-primary-500" />
            ) : (
              <Icons.Heart className="text-neutral-400" />
            )}
          </button>
          <p className="text-xs font-medium leading-5 text-neutral-400">{agent.likes}</p>
        </div>
      )}
    </div>
  );
};

export default AgentTileCreatorInfo;
