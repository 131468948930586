import { DialogClose, DialogDescription, DialogFooter } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Icons } from "@/components/ui/icons";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useGetCommunityCategories } from "@/data/queries/useGetCommunityCategories";
import { useState } from "react";
import { Textarea } from "@/components/ui/textarea";
import { usePublishAgentToCommunity } from "@/data/mutations/usePublishAgentToCommunity";
import { AgentTile } from "../../../agentTiles/AgentTile/AgentTile";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { HybridTooltipPopover } from "@/components/HybridTooltipPopover";
import { useGetSingleCommunityAgent } from "@/data/queries/useGetSingleCommunityAgent";
import type { Step } from "../types/PublishAgentDialogSteps";
import { useQueryClient } from "@tanstack/react-query";
import type { F1 } from "@/types/types";
import {
  singlePrivateAgentWithConfigKeys,
  useGetSinglePrivateAgentWithConfig,
} from "@/data/queries/useGetSinglePrivateAgentWithConfig";

type PublishAgentDialogDetailsProps = {
  setPublishStep: F1<Step>;
  selectedAgentId: string;
  getPublishedAgentData: boolean;
};

export const PublishAgentDialogDetails = ({
  setPublishStep,
  selectedAgentId,
  getPublishedAgentData,
}: PublishAgentDialogDetailsProps) => {
  const { data: categories } = useGetCommunityCategories();
  const queryClient = useQueryClient();
  const agentDataSource = getPublishedAgentData ? useGetSingleCommunityAgent : useGetSinglePrivateAgentWithConfig;
  const { data: agent, isLoading: isLoadingAgent } = agentDataSource({ agentId: selectedAgentId });

  const { mutate, isPending } = usePublishAgentToCommunity();
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [descriptionInput, setDescriptionInput] = useState("");
  const isPublishButtonEnabled = selectedCategory && descriptionInput;
  const publishAgentToCommunity = () => {
    mutate(
      {
        agentId: selectedAgentId,
        categories: selectedCategory ? [selectedCategory] : [],
        description: descriptionInput,
        status: true,
      },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries({ queryKey: singlePrivateAgentWithConfigKeys.id(selectedAgentId) });
          setPublishStep("success");
        },
      }
    );
  };

  return (
    <>
      <DialogDescription className="mt-[-10px] flex h-full flex-col-reverse gap-10 md:flex-row">
        <div className="flex h-full flex-col gap-4 pr-4 md:w-[60%]">
          <h4>Details</h4>
          <Input label="Agent name" id="agentName" disabled value={agent?.name} />
          <div className="flex flex-col gap-1.5">
            <div className="flex gap-1">
              <Label id="category">Category</Label>
              <HybridTooltipPopover heading="Set a category:">
                Set a category:
                <p>Select the category that best describes your Agent </p>
              </HybridTooltipPopover>
            </div>
            <Select
              onValueChange={e => {
                setSelectedCategory(e);
              }}
              value={selectedCategory}
            >
              <SelectTrigger autoFocus id="category" className="w-full">
                <SelectValue placeholder="Pick a category"></SelectValue>
              </SelectTrigger>
              <SelectContent>
                {categories?.map(category => (
                  <SelectItem key={category.title} value={category._id}>
                    {category.title}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="flex flex-col gap-1.5">
            <div className="flex gap-1">
              <Label htmlFor="agentDescriptions">Agent Descriptions</Label>
              <HybridTooltipPopover heading="Describe Your Bot:">
                <p>
                  Provide a brief yet impactful description of your Agent, highlighting its key features and unique
                  capabilities. This is your chance to showcase its personality and functionality in just a few words
                </p>
              </HybridTooltipPopover>
            </div>
            <p className="text-xs text-neutral-400">
              The description determines the chance to be discovered. Make it detail and clear.
            </p>
            <Textarea
              id="agentDescriptions"
              value={descriptionInput}
              onChange={e => setDescriptionInput(e.target.value)}
              placeholder="For example: dynamic AI tutor designed to make learning interactive, personalized, and accessible anytime, anywhere. This AI agent can ...'"
            />
          </div>
        </div>

        <div className="border-neutral-200 md:w-[40%] md:border-l md:p-4">
          <h4 className="w-full">Preview of Community Page</h4>
          <div className="relative mt-5 w-full flex-1 md:ml-7 md:mt-12">
            {agent && <AgentTile agent={{ ...agent, description: descriptionInput }} hideLikesCount />}
            {isLoadingAgent && <LoadingSpinner className="flex flex-1 items-center justify-center" />}
          </div>
        </div>
      </DialogDescription>

      <DialogFooter>
        <DialogClose asChild>
          <Button variant="tertiary" size="md">
            Cancel
          </Button>
        </DialogClose>
        <Button
          className="flex gap-1"
          size="md"
          onClick={publishAgentToCommunity}
          disabled={!isPublishButtonEnabled}
          loading={isPending}
        >
          <Icons.Upload /> Publish
        </Button>
      </DialogFooter>
    </>
  );
};
