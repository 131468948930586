import type { Conversation, Message } from "@/types/conversation";
import { restClient } from "./initAxios";
import { apiPaths } from "./apiPaths";
import { sleep } from "@/utils/sleep";

type Props = {
  conversationId: Conversation["_id"];
  messageId: Message["_id"];
  retries?: number;
};

export const getSingleMessage = async ({ conversationId, messageId }: Props) => {
  const { data } = await restClient.get<Message>(apiPaths.getConversationSingleMessage({ conversationId, messageId }));
  return data;
};

export const getSingleMessageWithRetry = async ({
  conversationId,
  messageId,
  retries = 3,
}: Props): Promise<Message | null | undefined> => {
  try {
    const data: Message | null = await getSingleMessage({ conversationId, messageId });

    if (!data && retries > 0) {
      await sleep(500);
      return await getSingleMessageWithRetry({ conversationId, messageId, retries: retries - 1 });
    }

    return data;
  } catch (error) {
    if (retries > 0) {
      return getSingleMessageWithRetry({ conversationId, messageId, retries: retries - 1 });
    } else {
      console.log("Error fetching message", error);
    }
  }
};
