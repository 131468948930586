import { useQuery } from "@tanstack/react-query";
import type { Agent } from "@/types/agent";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { ChatAgent } from "@/types/conversation";

export const singleConversationAgentGuestKeys = {
  all: ["singleConversationAgentGuestKeys"] as const,
  id: (id: ChatAgent["_id"]) => [...singleConversationAgentGuestKeys.all, id] as const,
};

type Props = {
  agentId: ChatAgent["_id"];
};

export const getSingleConversationAgentGuest = async (agentId: Agent["_id"]) => {
  const { data } = await restClient.get<ChatAgent>(apiPaths.getSingleConversationAgentPublic(agentId));
  return data;
};

export const useGetSingleConversationAgentGuest = ({ agentId }: Props) => {
  const query = useQuery({
    queryKey: singleConversationAgentGuestKeys.id(agentId),
    queryFn: () => getSingleConversationAgentGuest(agentId),
  });

  return query;
};
