import { WorkspaceMyAgents } from "@/pages/Workspace/components/WorkspaceMyAgents";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { FetchNextPageTrigger } from "@/components/Chat/components/FetchNextPageTrigger";
import { useGetAgentsWithConfig } from "@/data/queries/useGetAgentsWithConfig";

export const MyAgentsSpace = () => {
  const {
    data: myAgents,
    isPending: isMyAgentsWithConfigPending,
    isSuccess: isMyAgentsWithConfigSuccess,
    fetchNextPage: myAgentsFetchNextPage,
    hasNextPage: myAgentsHasNextPage,
    isFetchingNextPage: isMyAgentsFetchingNextPage,
  } = useGetAgentsWithConfig();

  return (
    <>
      <h4 className="mb-4 text-left font-bold">My Agents</h4>
      <WorkspaceMyAgents
        agents={myAgents ?? []}
        isLoading={isMyAgentsWithConfigPending}
        isSuccess={isMyAgentsWithConfigSuccess}
        canEdit
      />

      {isMyAgentsFetchingNextPage && <LoadingSpinner className="my-5 flex items-center justify-center" />}

      {myAgentsHasNextPage && <FetchNextPageTrigger fetchNextPage={myAgentsFetchNextPage} />}
    </>
  );
};
