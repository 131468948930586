import { Navigate, Outlet } from "react-router-dom";
import { ROUTES } from "@/constants/routes";
import { useGetUser } from "@/data/queries/useGetUser";
import { Icons } from "@/components/ui/icons";
import { LoginContainer } from "@/components/ui/login-container";

export const LoginRoutes = () => {
  const token = localStorage.getItem("token");
  const { user } = useGetUser({ enabled: !!token });

  if (user) {
    return <Navigate to={ROUTES.workspace} replace />;
  }

  return (
    <>
      <Icons.AgentLogo className="mx-auto mb-[15px] mt-6 max-h-8 w-[100px] sm:absolute sm:left-1/2 sm:top-[6%] sm:mt-0 sm:w-44 sm:-translate-x-1/2 xl:w-[200px]" />
      <LoginContainer>
        <Outlet />
      </LoginContainer>
    </>
  );
};
