import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { Icons } from "@/components/ui/icons";
import { useMediaQueriesContext } from "@/contexts/MediaQueriesContext/useMediaQueriesContext";
import { SideBarNav } from "./SideBarNav";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

export const MobileNav = ({ isGuestUser }: { isGuestUser: boolean }) => {
  const { isMobile } = useMediaQueriesContext();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);

  if (!isMobile) {
    return null;
  }

  const handleOpenChange = (isOpen: boolean) => {
    setIsOpen(isOpen);
  };

  return (
    <Sheet onOpenChange={handleOpenChange} open={isOpen}>
      <SheetTrigger>
        <Icons.Menu className="size-6" />
      </SheetTrigger>
      <SheetContent className="!max-w-[170px] rounded-r-3xl" side="left">
        <SideBarNav mobileNav onCloseMobileNav={() => setIsOpen(false)} isGuestUser={isGuestUser} />
      </SheetContent>
    </Sheet>
  );
};
