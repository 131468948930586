import { Icons } from "@/components/ui/icons";
import { cn } from "@/lib/utils";
import type { F0 } from "@/types/types";

type PublishNewAgentProps = {
  onClick: F0;
  text: string;
  className?: string;
  disabled?: boolean;
};

export const PublishNewAgent = ({ onClick, text, className, disabled }: PublishNewAgentProps) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={cn(
        "flex min-h-44 w-full flex-col items-center justify-center gap-3 rounded-lg border border-neutral-200 bg-neutral-100 4xl:w-auto 4xl:max-w-[260px] 4xl:basis-full",
        className,
        {
          "cursor-not-allowed": disabled,
        }
      )}
    >
      <div className="flex size-10 items-center justify-center rounded-md bg-neutral-200">
        <Icons.Plus className="p-0.5" />
      </div>
      <div className="text-sm font-medium">{text}</div>
    </button>
  );
};
