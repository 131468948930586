import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import type { ButtonProps } from "@/components/ui/button";
import { Button } from "@/components/ui/button";
import type { Agent as AgentType, AgentFormFields, FullAgent } from "@/types/agent";
import { AgentState, agentFormDefaultValues } from "@/types/agent";
import type { FieldErrors } from "react-hook-form";
import { FormProvider, useForm } from "react-hook-form";
import { BottomBar } from "@/components/AgentForm/components/BottomBar";
import { pickAgentAvatar } from "@/utils/pickAvatar";
import { ROUTES } from "@/constants/routes";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { agentsKeys } from "@/data/queries/useGetAgents";
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@/components/ui/resizable";
import { NavigationBlockerDialog } from "./components/NavigationBlockerDialog";
import { useMediaQueriesContext } from "@/contexts/MediaQueriesContext/useMediaQueriesContext";
import { Icons } from "@/components/ui/icons";
import { Avatar } from "@/components/Avatar";
import { cn } from "@/lib/utils";
import { Chat } from "@/components/Chat/Chat";
import { agentCreationId } from "@/constants/agentCreationId";
import { calculateTimeDifference } from "@/utils/calculateTimeDifference";
import { AgentFormContent } from "./components/AgentFormContent";
import { AgentTabsMobile } from "./components/AgentTabsMobile";
import { domElementIds } from "@/types/dom-element-ids";
import { zodResolver } from "@hookform/resolvers/zod";
import { AgentFormValidationSchema } from "./AgentFormValidationSchema";
import { useAgentFormNavigationTab } from "./hooks/useAgentFormNavigationTab";
import { agentTabs } from "./types/AgentFormTypes";
import { AgentTabs } from "./components/AgentTabs";
import type { AgentFormOnSubmit } from "./types/AgentFormTypes";
import { useAgentFormBlocker } from "./hooks/useAgentFormBlocker";
import { useGetUser } from "@/data/queries/useGetUser";

type AgentFormProps = {
  agentCreation?: boolean;
  defaultFormValues?: AgentFormFields;
  agentData?: FullAgent;
  onSubmitForm: AgentFormOnSubmit;
  disableFormNavigation: boolean;
  isChatError?: boolean;
  submitBtn: {
    text: string;
    variant?: ButtonProps["variant"];
    disabled: boolean;
    loading: boolean;
  };
  isLoadingChat?: boolean;
  isLoadingFormContent?: boolean;
};

export const AgentForm = ({
  agentCreation,
  defaultFormValues,
  agentData,
  onSubmitForm,
  disableFormNavigation,
  isChatError = false,
  submitBtn,
  isLoadingChat = false,
  isLoadingFormContent = false,
}: AgentFormProps) => {
  const { user } = useGetUser();
  const queryClient = useQueryClient();
  const { isMobile } = useMediaQueriesContext();
  const navigate = useNavigate();
  const { currentTabIndex, isFirstTab, isLastTab, switchTab } = useAgentFormNavigationTab();

  const [shouldDisplayMobileChat, setShouldDisplayMobileChat] = useState(false);
  agentFormDefaultValues.company = user?.name || "";
  const form = useForm<AgentFormFields>({
    defaultValues: defaultFormValues ?? agentFormDefaultValues,
    resolver: zodResolver(AgentFormValidationSchema({ agentType: agentData?.agentType })),
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { isDirty, dirtyFields },
    setError,
  } = form;

  const { blocker } = useAgentFormBlocker(isDirty);

  const formAgentName = watch("name");
  const agentDisplayName = agentData?.name ? agentData.name : !formAgentName ? "Agent Name" : formAgentName;

  useEffect(() => {
    if (agentCreation) {
      reset({ ...agentFormDefaultValues, avatar: pickAgentAvatar() });
      switchTab({ newTab: agentTabs[0] });
      return;
    }
  }, [agentCreation, switchTab, reset]);

  const submitForm = (formValues: AgentFormFields) => {
    onSubmitForm({
      formValues,
      onSetFormError: setError,
      onReset: reset,
      isLeadGenToolDirty: !!dirtyFields.leadGeneration,
    });
  };

  const onFormSubmitError = (errors: FieldErrors<AgentFormFields>) => {
    if (Object.keys(errors).length > 0) {
      const errorMessages = {
        inMemoryKnowledge: "Onboarding information",
        embedKnowledges: "FAQ",
        company: "Creator name",
        name: "Agent name",
        avatar: "Avatar",
        description: "Description",
        leadGeneration: "Lead generation",
        publish: "Publish",
      };
      const errorMessageHeader = "Please correct the following errors:\n";

      type ErrorKey = keyof typeof errors;

      const errorMessagesArray = Object.keys(errors).map(key => {
        if (key in errors) {
          const safeKey = key as ErrorKey;
          const fieldError = errors[safeKey] as { message: string } | undefined;
          const fieldErrorMessage = fieldError?.message
            ? fieldError.message
            : (errorMessages as Record<string, string>)[safeKey] || "Invalid field";
          return fieldErrorMessage;
        }
        return "Invalid field: "; // Fallback error message
      });

      const errorMessageSummary = errorMessageHeader + errorMessagesArray.join(", ");

      console.log("Form errors summary:", errorMessageHeader + errorMessageSummary);
      console.log(errors);
      toast.error(errorMessageSummary);
    }
  };

  const onNextPrevButtonClick = (type: "next" | "prev") => {
    const newTabIndex = type === "next" ? currentTabIndex + 1 : currentTabIndex - 1;
    const newTab = agentTabs[newTabIndex];

    if (newTab) {
      if (isMobile) {
        const scrollElement = document.getElementById(domElementIds.AGENT_FORM_MOBILE_SCROLL_TO);
        scrollElement?.scrollIntoView({ behavior: "instant", block: "start", inline: "start" });
      }
      switchTab({ newTab });
    }
  };

  const onCancelClick = () => {
    if (agentCreation) {
      navigate(ROUTES.workspace);
      queryClient.setQueryData<AgentType[]>(agentsKeys.all, prev =>
        prev?.filter(agent => agent._id !== agentCreationId)
      ); // remove draft agent from cache
    } else {
      reset();
    }
  };

  if (isMobile) {
    if (shouldDisplayMobileChat) {
      return (
        <Chat
          agents={[agentData]}
          isLoading={isLoadingChat}
          isError={isChatError}
          onBackClick={() => setShouldDisplayMobileChat(false)}
        />
      );
    }

    return (
      <FormProvider {...form}>
        <div className="relative mx-auto flex w-full flex-col overflow-auto pt-4">
          <div className="flex items-center justify-between gap-2 px-5 pb-4">
            <div className="flex items-center gap-2">
              <Button
                variant="ghost"
                size="custom"
                onClick={() => (shouldDisplayMobileChat ? setShouldDisplayMobileChat(false) : navigate(-1))}
              >
                <Icons.Arrow className="size-6" />
                <span className="sr-only">Go back</span>
              </Button>
              <div className="flex items-center gap-2">
                <Avatar
                  src={agentData?.avatar || form.watch("avatar")}
                  name={agentData?.name || formAgentName}
                  size="m"
                />
                <div className="grid">
                  <span
                    className={cn("truncate text-sm font-semibold", {
                      "text-gray-500": !formAgentName,
                    })}
                  >
                    {agentDisplayName}
                  </span>
                  <span className="text-xs font-medium text-gray-400">
                    {agentCreation ? "Create Agent" : "Edit Agent"}
                  </span>
                </div>
              </div>
            </div>
            {!agentCreation && !shouldDisplayMobileChat && (
              <Button
                disabled={disableFormNavigation}
                className="flex items-center gap-2 border-2"
                variant="tertiary"
                onClick={() => setShouldDisplayMobileChat(true)}
              >
                <Icons.Chat className="size-4" />
                Preview
              </Button>
            )}
          </div>
          <div className="flex flex-col overflow-auto px-5 pb-6">
            <AgentTabsMobile
              onNextPrevButtonClick={onNextPrevButtonClick}
              disabled={disableFormNavigation}
              isAgentCreation={!!agentCreation}
            />

            <AgentFormContent isLoading={isLoadingFormContent} agentState={agentData?.state} />

            {/* bottom navigation */}
            {agentData?.state !== AgentState.AGENT_UPDATING && agentData?.state !== AgentState.AGENT_CREATING && (
              <>
                <div className="ml-auto mt-5 space-x-2">
                  <Button
                    variant="tertiary"
                    onClick={() => onNextPrevButtonClick("prev")}
                    disabled={isFirstTab || disableFormNavigation}
                  >
                    <Icons.Arrow className="size-4" />
                  </Button>
                  <Button onClick={() => onNextPrevButtonClick("next")} disabled={isLastTab || disableFormNavigation}>
                    <Icons.Arrow className="size-4 rotate-180" />
                  </Button>
                </div>
                <hr className="my-6" />

                <Button
                  className="self-center"
                  type="submit"
                  variant={submitBtn.variant}
                  onClick={handleSubmit(submitForm, onFormSubmitError)}
                  disabled={!isDirty || submitBtn.disabled}
                  loading={submitBtn.loading}
                >
                  {submitBtn.text}
                </Button>
              </>
            )}
          </div>
          {blocker.state === "blocked" && <NavigationBlockerDialog blocker={blocker} />}
        </div>
      </FormProvider>
    );
  }

  return (
    <FormProvider {...form}>
      <div className="border-r-[1.5px] border-r-neutral-200 px-4 py-6">
        <Button
          onClick={() => navigate(ROUTES.workspace)}
          variant="ghost"
          className="relative mb-4 gap-6 px-12 py-2 text-2xl font-bold text-primary-black"
        >
          <Icons.ChevronRight className="absolute left-0 top-1/2 size-6 -translate-y-1/2 rotate-180 text-primary-black" />
          {agentCreation ? "Create Agent" : "Edit Agent"}
        </Button>

        <AgentTabs disabled={disableFormNavigation} agentExist={!agentCreation} />
      </div>

      <ResizablePanelGroup direction="horizontal" className="relative flex flex-1 overflow-hidden bg-white">
        <ResizablePanel
          className="relative flex w-[60%] flex-col border-r-[1.5px] border-neutral-200 p-0 pb-[88px]"
          defaultSize={65}
          minSize={43}
        >
          <div className="flex items-center justify-start gap-4 border-b border-b-neutral-200 p-4">
            <div className="flex items-center gap-4 p-2">
              <Avatar
                src={agentData?.avatar || form.watch("avatar")}
                name={agentData?.name || formAgentName}
                size="m"
              />
              <span
                className={cn("truncate text-xl font-bold", {
                  "text-gray-500": !formAgentName,
                })}
              >
                {agentDisplayName}
              </span>
            </div>
            {!!agentData?.createdAt && (
              <span className="text-xs font-medium text-neutral-500">
                Born: {calculateTimeDifference(agentData.createdAt, { strict: true })}
              </span>
            )}
          </div>

          <AgentFormContent isLoading={isLoadingFormContent} agentState={agentData?.state} />

          <BottomBar>
            <Button
              disabled={!agentCreation && !isDirty}
              className="flex gap-1"
              onClick={onCancelClick}
              variant="tertiary"
              size="md"
            >
              Cancel
            </Button>

            <Button
              className="m-2 flex gap-1"
              size="md"
              type="submit"
              variant={submitBtn.variant}
              onClick={handleSubmit(submitForm, onFormSubmitError)}
              disabled={!isDirty || submitBtn.disabled}
              loading={submitBtn.loading}
            >
              {submitBtn.text}
            </Button>

            <div className="ml-auto mr-4 space-x-4">
              <Button
                variant="tertiary"
                size="md"
                onClick={() => onNextPrevButtonClick("prev")}
                disabled={isFirstTab || disableFormNavigation}
              >
                {"<- Back"}
              </Button>
              <Button
                size="md"
                onClick={() => onNextPrevButtonClick("next")}
                disabled={isLastTab || disableFormNavigation || (currentTabIndex === 2 && agentCreation)}
              >
                {"Continue ->"}
              </Button>
            </div>
          </BottomBar>
        </ResizablePanel>

        <ResizableHandle withHandle />

        <ResizablePanel defaultSize={35} minSize={25}>
          <Chat agents={[agentData]} isLoading={isLoadingChat} isError={isChatError} />
        </ResizablePanel>
        {blocker.state === "blocked" && <NavigationBlockerDialog blocker={blocker} />}
      </ResizablePanelGroup>
    </FormProvider>
  );
};
