import { StatsHeader } from "./components/StatsHeader";
import StatsTopAgents from "./components/StatsTopAgents/StatsTopAgents";
import { StatsBasic } from "./components/StatsBasic";
import { StatsMainChartSection } from "./components/StatsMainChartSection";
import StatsAgentsOverview from "./components/StatsAgentsOverview/StatsAgentsOverview";
import { useStatsContext } from "@/contexts/StatsContext/useStatsContext";
import { AgentChatHistoryDialog } from "./components/AgentChatHistoryDialog/AgentChatHistoryDialog";

export const Stats = () => {
  const {
    currentRange,
    startDate,
    endDate,
    isConversationsListOpen,
    conversationsListAgentId,
    setConversationsListAgentId,
  } = useStatsContext();

  const StatsContent = (
    <>
      <StatsHeader />

      <div className="overflow-y-scroll px-5 pb-10 lg:px-10">
        <StatsBasic />

        <div className="mt-2.5 grid grid-cols-1 gap-4 sm:mt-7 min-[1881px]:grid-cols-2 min-[1881px]:gap-7">
          <StatsTopAgents />
          <div className="lg:row-span-2 xl:h-full">
            <StatsMainChartSection />
          </div>
          <StatsAgentsOverview />
        </div>
      </div>
    </>
  );

  return (
    <div className="flex w-full flex-col rounded-t-[20px] bg-stats-view pt-4 lg:pt-10">
      {StatsContent}
      <AgentChatHistoryDialog
        key={`${currentRange}-${startDate?.toString()}-${endDate?.toString()}`}
        isOpen={isConversationsListOpen}
        onClose={() => setConversationsListAgentId("")}
        agentId={conversationsListAgentId}
        initialCurrentRange={currentRange}
        initialStartDate={startDate}
        initialEndDate={endDate}
      />
    </div>
  );
};
