import { Avatar } from "@/components/Avatar";
import type { ChatAgent } from "@/types/conversation";
import { loaderArray } from "@/utils/loaderArray";
import { format } from "date-fns";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";

const animate = {
  y: [4, -4, 4, 4],
  opacity: [0.6, 1, 0.6, 0.6],
};

export const MessageLoader = ({
  agent,
  withDelay,
}: {
  agent: {
    _id: ChatAgent["_id"];
    name: ChatAgent["name"];
    avatar: ChatAgent["avatar"];
  };
  withDelay?: boolean;
}) => {
  const [isVisible, setIsVisible] = useState(!withDelay);

  useEffect(() => {
    if (!withDelay) {
      return;
    }

    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [withDelay]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className="flex flex-col p-4 hover:bg-primary-50">
      <div className="flex items-center gap-3">
        <Avatar name={agent.name} src={agent?.avatar} />
        <div className="flex gap-2">
          <div className="text-sm font-bold text-primary-black">{agent.name}</div>
          <p className="pt-[3px] text-xs text-neutral-500">{format(new Date(), "HH:mm")}</p>
        </div>
      </div>
      <div className="pl-[54px] text-sm font-medium text-primary-black">
        <div className="my-2 flex gap-1">
          {loaderArray(3).map((_, idx) => (
            <motion.div
              key={`generate-message-dot-${idx}`}
              transition={{ repeat: Infinity, duration: 1.5, delay: idx * 0.2 }}
              animate={animate}
              className="size-[5px] rounded-full bg-black bg-opacity-50"
            ></motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};
