import { useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { User } from "@/types/user";

export const userKeys = {
  all: ["user"] as const,
};

const getUser: () => Promise<User> = async () => {
  const { data } = await restClient.get<User>(apiPaths.user);
  window.agx = data.apiKey;
  return data;
};

type Props = {
  enabled?: boolean;
};

export const useGetUser = ({ enabled = true }: Props = {}) => {
  const query = useQuery({
    queryKey: userKeys.all,
    queryFn: enabled ? getUser : undefined,
    enabled,
    staleTime: Infinity,
    gcTime: Infinity,
    retry: 1,
  });

  return {
    ...query,
    user: query.data,
  };
};
