import { Label } from "@/components/ui/label";
import { HybridTooltipPopover } from "@/components/HybridTooltipPopover";
import { useAgentFormContext } from "../../../hooks/useAgentFormContext";
import { Textarea } from "@/components/ui/textarea";
import { get } from "lodash";

export const AgentBio = () => {
  const {
    register,
    watch,
    formState: { errors },
  } = useAgentFormContext();

  const placeholderText = `Define the agent character. How should it behave? What to avoid? (e.g. You are a sales person for company XXX. You have a good sense of humor! You should avoid talking politics.)`;

  return (
    <div>
      <div className="flex items-center gap-1 pb-2">
        <Label htmlFor="inMemoryKnowledge.about">Agent Bio</Label>
        <HybridTooltipPopover heading="The prompt of Agent Identity:">
          <p>
            Give your agent a unique background to create a more engaging experience. Think of it as introducing your
            bot to the world! You can also add guidelines or things your agent should do and should not do.
          </p>
        </HybridTooltipPopover>
      </div>

      <Textarea
        id="inMemoryKnowledge.about"
        register={register("inMemoryKnowledge.about")}
        placeholder={placeholderText}
        maxLength={2000}
        error={get(errors, "inMemoryKnowledge.about.message")}
        formValue={watch("inMemoryKnowledge.about")}
      />
    </div>
  );
};
