import { KnowledgeStatus } from "@/types/agent";
import { Icons } from "@/components/ui/icons";

export const KnowledgeStatusBadge = ({ status }: { status: string }) => {
  switch (status) {
    case KnowledgeStatus.FAILED:
      return (
        <span className="mr-1 inline-flex items-center rounded-full border border-red-500 bg-red-50 px-2 py-1 text-xs font-medium text-neutral-800 disabled:border-red-100 disabled:bg-red-100 disabled:text-red-200">
          <Icons.Error />
          <span className="pl-1">Failed</span>
        </span>
      );
    case KnowledgeStatus.REFRESH:
      return (
        <span className="mr-1 inline-flex items-center rounded-full border border-primary-500 bg-primary-50 px-2 py-1 text-xs font-medium text-neutral-800 disabled:border-primary-100 disabled:bg-primary-100 disabled:text-primary-200">
          <Icons.Refresh />
          <span className="pl-1">Refresh</span>
        </span>
      );
    default:
      return null;
  }
};
