import { useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { MessageBookmarkGroup } from "@/types/bookmarks";

export const userMessageBookmarksKeys = {
  all: ["userMessageBookmarks"] as const,
};

const getUserMessageBookmarks = async () => {
  const { data } = await restClient.get<MessageBookmarkGroup[]>(apiPaths.getUserMessageBookmarks);
  return data;
};

export const useGetUserMessageBookmarks = ({ enabled = true }: { enabled?: boolean } = {}) => {
  const query = useQuery({
    queryKey: userMessageBookmarksKeys.all,
    queryFn: getUserMessageBookmarks,
    refetchOnMount: true,
    enabled,
  });

  return query;
};
