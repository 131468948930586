import { HybridTooltipPopover } from "@/components/HybridTooltipPopover";
import { KnowledgeLimitInfoBadge } from "./KnowledgeLimitInfoBadge";
import { domElementIds } from "@/types/dom-element-ids";
import { DocumentInfoLocal } from "./DocumentInfoLocal";
import { useFieldArray } from "react-hook-form";
import { useGetUser } from "../../../../../data/queries/useGetUser";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useState } from "react";
import { DocumentInfoGoogleDrive } from "./DocumentInfoGoogleDrive";

const documentInfoTabs: DocumentInfoTabs[] = ["Local Documents", "Google Drive"];

type DocumentInfoTabs = "Local Documents" | "Google Drive";

const documentInfoTabMap: {
  [K in DocumentInfoTabs]: React.ComponentType;
} = {
  "Local Documents": DocumentInfoLocal,
  "Google Drive": DocumentInfoGoogleDrive,
};

export const DocumentInfo = () => {
  const { user } = useGetUser();
  const [activeDocumentInfoTab, setActiveDocumentInfoTab] = useState<DocumentInfoTabs>("Local Documents");
  const ActiveInfoTab = documentInfoTabMap[activeDocumentInfoTab];

  const userDocsLimit = user?.subscription.knowledge.docs?.limit;

  const { fields: documentsData } = useFieldArray({
    name: "embedKnowledges.doc",
  });

  return (
    <div id={domElementIds.AGENT_FORM_DOCUMENT_INFO}>
      <div className="flex items-center gap-2 py-5">
        <h4>2. Add document info</h4>
        <HybridTooltipPopover heading="Expand Knowledge from your documents">
          <p>
            Provide documents that your Agent will use as a reference or knowledge base. This can be anything from FAQs,
            guidelines, manuals, to topic-specific literature, enriching the Agent's responses with detailed and
            specific information.
          </p>
        </HybridTooltipPopover>

        <KnowledgeLimitInfoBadge currentUsage={documentsData.length} limit={userDocsLimit} />
      </div>
      <div className="flex flex-col gap-4 md:pl-5">
        <Tabs value={activeDocumentInfoTab} className="w-full space-y-4">
          <TabsList className="w-full bg-neutral-100 p-1">
            {documentInfoTabs.map(tab => (
              <TabsTrigger
                key={tab}
                value={tab}
                onClick={() => setActiveDocumentInfoTab(tab)}
                className="flex-1 text-neutral-600 data-[state=active]:text-neutral-750"
              >
                {tab}
              </TabsTrigger>
            ))}
          </TabsList>
        </Tabs>
        <ActiveInfoTab />
      </div>
    </div>
  );
};
