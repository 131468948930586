import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { Icons } from "@/components/ui/icons";
import { useGetWorkspaceDetails } from "@/data/queries/workspace/useGetWorkspaceDetails";
import { CustomerDetails } from "@/pages/Workspace/components/CustomerSpace/CustomerDetails";
import { LeaveWorkspaceMemberDialog } from "@/components/dialogs/TeamDialogs/LeaveWorkspaceMemberDialog";

export const CustomerDropdownMenu = () => {
  const { data: workspaceDetails } = useGetWorkspaceDetails();

  const isWorkspaceAdmin = workspaceDetails?.isAdmin;

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger onClick={e => e.stopPropagation()} asChild>
        <Button
          size="custom"
          variant="tertiary"
          roundedType="rounded"
          className="h-10 w-10 min-w-7 border-none hover:bg-neutral-100 active:bg-neutral-300"
        >
          <Icons.ChevronRight className="h-6 w-6 rotate-90 text-black" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" className="flex flex-col gap-3 px-4 py-2.5">
        <CustomerDetails />
        {(!isWorkspaceAdmin || (isWorkspaceAdmin && workspaceDetails.customer.administrators.length !== 1)) && (
          <>
            <div className="border-b-[1.5px]" />
            <DropdownMenuItem
              className="flex items-center gap-2 text-xs"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <LeaveWorkspaceMemberDialog />
            </DropdownMenuItem>
          </>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
