import { motion } from "framer-motion";
import type { PropsWithChildren } from "react";

const hoverAnimation = {
  initial: { width: 40 },
  transition: { duration: 0.4 },
  whileHover: { width: "fit-content" },
};

export const SideBarNavItem = ({ isMobile, children, ...props }: PropsWithChildren<{ isMobile: boolean }>) => {
  if (isMobile) {
    return (
      <div className="overflow-hidden rounded-md border border-neutral-300" {...props}>
        {children}
      </div>
    );
  }

  return (
    <motion.div
      {...hoverAnimation}
      {...props}
      className="relative z-10 overflow-hidden rounded-md border border-neutral-300"
    >
      {children}
    </motion.div>
  );
};
