export type Tool = {
  toolFunction: ToolFunction;
  private: boolean;
  _id: string;
  creator: string;
  toolName: string;
  toolDescription: string;
  parameters: Parameter[];
  deleted: boolean;
  createdAt: string;
  updatedAt: string;
  hidden?: boolean;
  disabled?: boolean;
  webhook: {
    enabled: boolean;
    url: string;
    header?: string[];
    method?: string;
  };
  isEditing?: boolean; // only for react hook form
  isAssigning?: boolean; // only for react hook form
  emailVendor?: "GMAIL" | "";
  disableEmail?: boolean; // if true, lead gen will not send email
};

export type Parameter = {
  parameterName: string;
  parameterDescription: string;
  required: boolean;
  type: "integer" | "string";
  _id: string;
};

export type ToolFunction = {
  language: string;
  code: string;
};

export type BlankTool = Omit<Tool, "_id" | "parameters"> & { parameters: Omit<Parameter, "_id">[] };

export const blankTool: BlankTool = {
  toolFunction: {
    language: "python",
    code: "",
  },
  private: false,
  creator: "",
  toolName: "",
  toolDescription: "",
  parameters: [],
  deleted: false,
  createdAt: "",
  updatedAt: "",
  webhook: {
    enabled: false,
    url: "",
    header: [""],
    method: "POST",
  },
};

export const sampleTool: BlankTool = {
  toolFunction: {
    language: "python",
    code: `def run(args_json:dict):
    # put your code here
    return json.dumps({“response”: “change me”, “success”: True})
`,
  },
  private: false,
  creator: "1",
  toolName: "get_me_suggestion",
  toolDescription: `Always use this tool when customer is interested in order a bus.

  Try to extract following key information as parameters:
  “New Bus Inquiry”, “Country”, “Usage”, “How many Seats”, “Quantity”, “Contract Info”
  ———
  Here are examples:
  conversations:
  ———

  Customer: Hi how are you
  Customer: How much yotong bus 70 seater
  Merchant: Which country the bus will be used?
  Customer: South Africa
  ———

  Key info
  ———

  {
  “New Bus Inquiry”: “True”,
  “Country”: “South Africa”,
  “Usage”: “NA”
  “How Many Seats”: “NA”,
  “Quantity”: “NA”,
  “Contact Info”: “NA”
  }`,
  parameters: [
    {
      parameterName: "Country",
      parameterDescription: "Country where the bus will be used",
      required: true,
      type: "string",
    },
    {
      parameterName: "Usage",
      parameterDescription: "How the bus will be used",
      required: false,
      type: "string",
    },
    {
      parameterName: "How Many Seats",
      parameterDescription: "Number of seats in the bus",
      required: false,
      type: "integer",
    },
    {
      parameterName: "Quantity",
      parameterDescription: "Number of buses",
      required: false,
      type: "integer",
    },
    {
      parameterName: "Contact Info",
      parameterDescription: "Contact information of the customer",
      required: false,
      type: "string",
    },
  ],
  deleted: false,
  webhook: {
    enabled: false,
    url: "",
    header: [""],
    method: "POST",
  },
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
};
