import { useState } from "react";
import { Button } from "@/components/ui/button";
import { useFieldArray } from "react-hook-form";
import { Label } from "@/components/ui/label";
import { Icons } from "@/components/ui/icons";
import { Input } from "@/components/ui/input";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { HybridTooltipPopover } from "@/components/HybridTooltipPopover";
import type { EventFor } from "@/types/types";
import { useAgentFormContext } from "../../../hooks/useAgentFormContext";

const GREETING_MAX_LENGTH = 300;

export const GreetingMessages = () => {
  const { control } = useAgentFormContext();

  const [newGreeting, setNewGreeting] = useState("");
  const [accordionValueOpen, setAccordionValueOpen] = useState("");
  const [newItemsIdsList, setNewItemsIdsList] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [editingIndex, setEditingIndex] = useState(null as number | null);
  const [tempMessage, setTempMessage] = useState("");

  const {
    fields: greetingData,
    append: appendGreeting,
    remove: removeGreeting,
    update: updateGreeting,
  } = useFieldArray({
    control,
    name: "definedActions.greeting",
  });

  const addGreeting = (e: EventFor<"form", "onSubmit">) => {
    e.preventDefault();
    if (greetingData.some(greeting => greeting.message === newGreeting)) {
      return setErrorMessage("This greeting already exists");
    }
    appendGreeting({ message: newGreeting });
    const newGreetingId = greetingData[greetingData.length - 1]?.id;
    setNewItemsIdsList([...newItemsIdsList, newGreetingId]);
    setAccordionValueOpen("greeting");
    setNewGreeting("");
    setErrorMessage("");
  };

  const saveGreeting = (index: number) => {
    updateGreeting(index, {
      message: tempMessage,
    });
    setEditingIndex(null);
  };

  const placeholderText = `Hi there! 👋 I'm Ada, your virtual guide!`;

  return (
    <div>
      <div className="mb-2 flex items-center gap-1">
        <Label htmlFor="greeting">Greeting messages</Label>
        <HybridTooltipPopover heading="How should agent greet users:">
          <p>
            Write a welcoming greeting message for your Agent. This is the first message users will see, so make it
            friendly and inviting. Use it to introduce your Agent.
          </p>
        </HybridTooltipPopover>
      </div>

      <form onSubmit={addGreeting} className="mb-2 flex flex-col gap-2 sm:flex-row">
        <Input
          id="greeting"
          value={newGreeting}
          onChange={e => setNewGreeting(e.target.value)}
          placeholder={placeholderText}
          error={errorMessage}
          maxLength={GREETING_MAX_LENGTH}
        />
        <Button type="submit" disabled={!newGreeting.trim().length}>
          Add greeting
        </Button>
      </form>

      <Accordion
        type="single"
        collapsible
        className="rounded-md border border-slate-200"
        value={accordionValueOpen}
        onValueChange={setAccordionValueOpen}
      >
        <AccordionItem value="greeting">
          <AccordionTrigger className="px-4 text-xs font-medium text-neutral-800">
            <div className="flex h-4 min-w-[16px] items-center justify-center rounded-full bg-primary-500 px-1 text-[10px] font-medium leading-none text-white">
              {greetingData.length}
            </div>
            <div className="ml-2 mr-auto">List of greetings</div>
          </AccordionTrigger>
          <AccordionContent className="flex flex-col gap-2 bg-zinc-100 p-2">
            {greetingData.map((greeting, index) => (
              <div
                key={greeting.message}
                className="flex w-full items-center justify-between gap-2 rounded-md border border-slate-200 bg-white hover:bg-primary-50"
              >
                {editingIndex === index ? (
                  <div className="flex w-full flex-col items-end justify-between gap-2 py-2 sm:flex-row sm:items-center sm:py-0">
                    <Input
                      value={tempMessage}
                      onChange={e => setTempMessage(e.target.value)}
                      maxLength={GREETING_MAX_LENGTH}
                    />
                    <div className="ml-2 flex items-center gap-2">
                      <Button
                        variant="tertiary"
                        type="button"
                        onClick={() => {
                          setTempMessage("");
                          setEditingIndex(null);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="secondary"
                        type="button"
                        onClick={() => {
                          saveGreeting(index);
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="flex w-full items-center justify-between px-2 py-1">
                    <p className="text-xs font-medium leading-3 text-neutral-800 custom-break-anywhere">
                      {greeting.message}
                    </p>
                    <div className="flex items-center gap-2">
                      <Button
                        size="action-icon"
                        className="p-1"
                        variant="ghost"
                        type="button"
                        onClick={() => removeGreeting(index)}
                      >
                        <Icons.Trash className="text-neutral-400" />
                      </Button>
                      <Button
                        size="action-icon"
                        variant="ghost"
                        type="button"
                        className="p-1"
                        onClick={() => {
                          setTempMessage(greeting.message);
                          setEditingIndex(index);
                        }}
                      >
                        <Icons.Edit className="text-neutral-400" />
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};
