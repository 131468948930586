import AgentSizeupAvatarDialog from "@/components/dialogs/AgentAvatarSizeupDialog/AgentAvatarSizeupDialog";

import { Avatar } from "@/components/Avatar";
import { Label } from "@/components/ui/label";
import type { LlmList } from "@/types/config";
import { useGetAppConfig } from "@/data/queries/useGetAppConfig";
import type { Agent, EmbedKnowledges } from "@/types/agent";
import type { ChatAgent } from "@/types/conversation";
import { useLikeAgent } from "@/data/mutations/useLikeAgent";
// import { useSubscribeAgent } from "@/data/mutations/useSubscribeAgent";
// import { useGetUser } from "@/data/queries/useGetUser";
import { useNavigate } from "react-router";
import { ROUTES } from "@/constants/routes";
import { Icons } from "@/components/ui/icons";
import { Button } from "@/components/ui/button";
import { ShareAgentDialog } from "@/components/dialogs/ShareAgentDialog/ShareAgentDialog";
import AgentDetailsEmbedKnowledge from "./components/AgentDetailsEmbedKnowledge";
import { useIsGuestUser } from "@/hooks/useIsGuestUser";
import { useLoginDialogContext } from "@/contexts/LoginDialogContext/useLoginDialogContext";

type AgentDetailsWrapperProps = {
  agent: ChatAgent;
  isAgentDetailsOpen?: boolean;
  setIsAgentDetailsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  isMyAgent: boolean | undefined;
};

const AgentDetailsWrapper = ({ agent, isMyAgent }: AgentDetailsWrapperProps) => {
  const { isGuestUser } = useIsGuestUser();
  const { showLoginDialog } = useLoginDialogContext();

  const navigate = useNavigate();
  // const { user } = useGetUser();
  const { data: config } = useGetAppConfig();
  // const { mutate: subscribeAgentMutation, isPending } = useSubscribeAgent();
  const { mutate: likeAgentMutation } = useLikeAgent();

  const getModelData = (model: LlmList["model"]) => {
    const modelConfig = config?.llmList.find(llm => llm.model === model);
    return modelConfig;
  };

  const showCreatorUser = "creatorUser" in agent;
  const showCommunityActions = "likedByUser" in agent && agent.publishedToCommunity;

  const showEmbedKnowledge =
    isMyAgent &&
    (agent.embedKnowledges.web.length > 0 ||
      agent.embedKnowledges.doc.length > 0 ||
      agent.embedKnowledges.faq.length > 0);

  // const subscribeAgent = ({ agentId, status }: { agentId: Agent["_id"]; status: boolean }) => {
  //   subscribeAgentMutation({ agentId, status });
  // };

  const likeAgent = ({ agentId, status, profileId }: { agentId: Agent["_id"]; status: boolean; profileId: string }) => {
    likeAgentMutation({ agentId, status, profileId });
  };

  const filteredEmbedKnowledges = (embedKnowledges: ChatAgent["embedKnowledges"]) => {
    return {
      web: embedKnowledges.web,
      doc: embedKnowledges.doc.filter((item): item is EmbedKnowledges => !("isNewFolder" in item)) as EmbedKnowledges[],
      faq: embedKnowledges.faq,
    };
  };

  return (
    <div className="flex size-full h-full flex-col items-center gap-4 overflow-y-auto">
      <AgentSizeupAvatarDialog
        renderButton={() => <Avatar src={agent?.avatar} name={agent.name} size="l" className="cursor-pointer" />}
        src={agent?.avatar}
      />
      <p className="text-center text-xl font-bold">{agent.name}</p>

      {showCreatorUser && (
        <p
          className="cursor-pointer text-neutral-500"
          onClick={() => navigate(ROUTES.communityProfile(agent.creatorUser?._id || ""))}
        >
          @{agent.creatorUser?.name}
        </p>
      )}

      {showCommunityActions && (
        <>
          <div className="flex items-center gap-4">
            <button
              onClick={() => {
                if (isGuestUser) {
                  showLoginDialog();
                  return;
                }
                likeAgent({ agentId: agent._id, status: !agent.likedByUser, profileId: agent.creatorUser?._id || "" });
              }}
              className="flex h-[42px] min-h-[42px] items-center justify-center gap-3 rounded-[21px] bg-neutral-100 px-4 hover:bg-neutral-200"
            >
              {agent.likedByUser ? (
                <Icons.HeartFilled className="scale-[1.2] text-primary-500" />
              ) : (
                <Icons.Heart className="scale-[1.2] text-neutral-600" />
              )}
              <p className="text-sm font-semibold">{agent.likes}</p>
            </button>
            <Icons.Spacer className="scale-[1.3] text-neutral-400" />
            <div className="flex items-center gap-[8px] text-sm">
              <Icons.Chat className="h-5 text-neutral-600" />
              <p className="text-sm font-semibold">{agent.messages}</p>
            </div>
          </div>

          <div className="flex flex-wrap items-center justify-center gap-5">
            {/* <Button
              onClick={() => {
                subscribeAgent({ agentId: agent._id, status: !agent.subscribedByUser });
              }}
              loading={isPending}
              // disabled={isFetchingAgent}
              variant={agent.subscribedByUser ? "secondary" : "primary"}
            >
              {agent.subscribedByUser ? "Subscribed" : "Subscribe"}
            </Button> */}
            <ShareAgentDialog
              agentId={agent._id}
              agentName={agent.name}
              renderButton={() => (
                <Button
                  variant="secondary"
                  onClick={e => {
                    if (isGuestUser) {
                      e.preventDefault();
                      showLoginDialog();
                      return;
                    }
                  }}
                >
                  Share Agent
                </Button>
              )}
            />
          </div>
        </>
      )}

      <div className="w-full">
        <Label className="font-bold">LLM Model</Label>
        <div className="flex items-center gap-2 pt-1">
          <img src={getModelData(agent.llmModel)?.logo} className="size-8 rounded-md" />
          <p className="text-sm font-semibold">{getModelData(agent.llmModel)?.display}</p>
        </div>
      </div>

      {showEmbedKnowledge && (
        <AgentDetailsEmbedKnowledge embedKnowledges={filteredEmbedKnowledges(agent.embedKnowledges)} />
      )}

      <div className="w-full">
        <Label className="font-bold">Description</Label>
        <div className="text-sm">{agent.description}</div>
      </div>
    </div>
  );
};

export default AgentDetailsWrapper;
