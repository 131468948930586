import { motion } from "framer-motion";
import { cn } from "@/lib/utils";
import { Icons } from "@/components/ui/icons";
import { useGetWorkspaceDetails } from "@/data/queries/workspace/useGetWorkspaceDetails";
import { useNavigationProfileContext } from "@/contexts/NavigationProfileContext/useNavigationProfileContext";
import { NavigationProfileSections } from "@/components/Profiles/NavigationProfileSection";

export const InviteTeamMemberNavBtn = () => {
  const { data: workspaceDetails } = useGetWorkspaceDetails();
  const { setNavigationProfileOpen } = useNavigationProfileContext();

  const isWorkspaceAdmin = workspaceDetails?.isAdmin;

  if (!isWorkspaceAdmin) {
    return null;
  }

  const handleNavigationProfileOpen = () => {
    setNavigationProfileOpen({ open: true, section: NavigationProfileSections.TEAM });
  };

  return (
    <div className="relative duration-500 animate-in fade-in zoom-in">
      <motion.div
        {...{
          initial: { width: 40, color: "#7844d3" },
          transition: { duration: 0.4 },
          whileHover: { width: 165, backgroundColor: "#5E33A8", color: "white" },
        }}
        className={cn(
          "flex h-10 min-w-10 cursor-pointer items-center gap-2 overflow-hidden lg:grid",
          "grid-cols-[16px_1fr] rounded-full bg-primary-100 px-2"
        )}
        onClick={handleNavigationProfileOpen}
      >
        <Icons.InvitePeople className="h-6 w-6" />
        <div className="hidden whitespace-nowrap pl-2 text-xs text-white lg:block">Invite team member</div>
      </motion.div>
    </div>
  );
};
