import * as React from "react";
import { cn } from "@/lib/utils";
import type { FieldError, UseFormRegisterReturn } from "react-hook-form";
import { useState } from "react";
import { Icons } from "@/components/ui/icons";
import { Button } from "./button";
import type { F0 } from "@/types/types";
import { ErrorMessage } from "./ErrorMessage";

export const inputDefaultClassName =
  "flex w-full rounded-md border border-neutral-300 bg-white px-3 py-2 text-base leading-relaxed text-neutral-700 ring-offset-white file:border-0 file:bg-transparent file:text-[0.75rem] file:font-medium placeholder:text-neutral-300 focus-visible:border-primary-300 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-primary-600 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:bg-neutral-50 dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300 sm:px-4 lg:text-xs lg:leading-relaxed";
export interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "className"> {
  label?: string;
  error?: FieldError | string;
  showRevealPasswordButton?: boolean;
  disableValidationMessage?: boolean;
  handleClear?: F0;
  containerClassName?: string;
  inputClassName?: string;
  register?: UseFormRegisterReturn;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type,
      label,
      error,
      showRevealPasswordButton,
      disableValidationMessage,
      handleClear,
      maxLength,
      children,
      containerClassName,
      inputClassName,
      id,
      register,
      ...props
    },
    ref
  ) => {
    const [displayPassword, setDisplayPassword] = useState(false);

    const errorClassName = error ? "border-red-500 border-2" : "";
    const rightPadding = maxLength || showRevealPasswordButton || handleClear ? "sm:pr-[60px] pr-[60px]" : "";

    const [internalCurrentValue, setInternalCurrentValue] = useState(props.value?.toString() ?? "");

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInternalCurrentValue(e.target.value);
      props?.onChange?.(e);
      void register?.onChange?.(e);
    };

    return (
      <div className={cn("flex w-full flex-col gap-1.5", containerClassName)}>
        {label && (
          <label
            htmlFor={props.name || id}
            className="whitespace-nowrap text-sm font-medium leading-relaxed text-slate-900 dark:text-slate-50"
          >
            {label}
          </label>
        )}
        <div className="relative">
          <input
            type={!displayPassword && showRevealPasswordButton ? "password" : type}
            id={props.name || id}
            className={cn(inputDefaultClassName, inputClassName, rightPadding, errorClassName)}
            ref={ref}
            {...props}
            {...register}
            onChange={handleChange}
            maxLength={maxLength}
            {...(!disableValidationMessage &&
              !!error && { "aria-invalid": true, "aria-errormessage": `input-error-${props.name || id}` })}
            {...register}
          />
          {!displayPassword && showRevealPasswordButton && (
            <Button
              type="button"
              variant="ghost"
              size="custom"
              className="absolute right-3 top-1/2 -translate-y-1/2"
              onClick={() => setDisplayPassword(true)}
            >
              <Icons.Eye className="size-5 text-black" aria-hidden />
              <span className="sr-only">show password</span>
            </Button>
          )}
          {displayPassword && showRevealPasswordButton && (
            <Button
              type="button"
              variant="ghost"
              size="custom"
              className="absolute right-3 top-1/2 -translate-y-1/2"
              onClick={() => setDisplayPassword(false)}
            >
              <Icons.EyeOff className="size-5" aria-hidden />
              <span className="sr-only">hide password</span>
            </Button>
          )}
          {handleClear && !!props.value?.toString().length && (
            <Button
              type="button"
              variant="ghost"
              size="custom"
              className="absolute right-3 top-1/2 size-6 -translate-y-1/2 p-1.5"
              onClick={handleClear}
            >
              <Icons.Close aria-hidden className="text-primary-black" />
              <span className="sr-only">clear</span>
            </Button>
          )}
          {maxLength && (
            <div
              aria-hidden
              className="absolute right-3 top-1/2 -translate-y-1/2 text-xs font-medium leading-none text-neutral-400"
            >
              {internalCurrentValue.length || 0}/{maxLength}
              {internalCurrentValue.length === maxLength && (
                <span className="sr-only" aria-live="polite">
                  You've reached the max number of characters
                </span>
              )}
            </div>
          )}
          {children}
        </div>
        {!disableValidationMessage && !!error && <ErrorMessage error={error} id={props.name || id} />}
      </div>
    );
  }
);
Input.displayName = "Input";

export { Input };
