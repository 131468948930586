import { cn } from "@/lib/utils";
import { UserStatus, type User } from "@/types/user";
import { Avatar } from "@/components/Avatar";
import { useGetWorkspaceDetails } from "@/data/queries/workspace/useGetWorkspaceDetails";
import { useGetUser } from "@/data/queries/useGetUser";

export const CustomerTeamMembers = ({ members }: { members: User[] | undefined }) => {
  const { user } = useGetUser();
  const { data: workspaceDetails } = useGetWorkspaceDetails();

  if (!members) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4">
      {members.map(member => {
        const memberInvitationPending = member.status === UserStatus.INVITE_PENDING;
        const isAdminMember = workspaceDetails?.customer?.administrators?.includes(member._id);
        const memberRole = isAdminMember ? "Admin" : memberInvitationPending ? "Pending" : "Member";
        return (
          <div key={member._id} className="flex w-full gap-4">
            <Avatar src={member?.avatar} name={member?.name} className="size-8 rounded-full lg:size-10" size="custom" />
            <div className="flex w-full items-center justify-between gap-5">
              <div>
                <div className="text-sm font-medium">
                  {member.name} {user?._id === member._id && <span className="text-neutral-400">(You)</span>}
                  {memberInvitationPending && <span className="text-neutral-500">{"<Invitiation sent>"}</span>}
                </div>
                <span className="text-xs text-neutral-400">{member.email}</span>
              </div>
              <span
                className={cn("text-right text-xs", memberInvitationPending ? "text-neutral-400" : "text-primary-400")}
              >
                {memberRole}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};
