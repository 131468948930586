import { StatOrigins } from "@/types/stats";

export const channelsLegendData = [
  {
    channel: StatOrigins.WORKSPACE,
    color: "#FFCE26",
    label: "Workspace",
  },
  {
    channel: StatOrigins.AGENT_SPACE,
    color: "#B1DFF0",
    label: "Agent Space",
  },
  {
    channel: StatOrigins.EMBEDDED,
    color: "#FF6384",
    label: "Web",
  },
  {
    channel: StatOrigins.DISCORD,
    color: "#EB95D3",
    label: "Discord",
  },
  {
    channel: StatOrigins.SLACK,
    color: "#FDF573",
    label: "Slack",
  },
  {
    channel: StatOrigins.WHATSAPP,
    color: "#25D366",
    label: "WhatsApp",
  },
];

export const ENGAGEMENT_STATS_COLORS_MAP = {
  agents: {
    label: "Agents",
    color: "#EB95D3",
    colorTransparent: "rgba(235, 143, 210, 0)",
  },
  messages: {
    label: "Messages",
    color: "#FDF573",
    colorTransparent: "rgba(235, 143, 210, 0)",
  },
  conversations: {
    label: "Conversations",
    color: "#DCD6F6",
    colorTransparent: "rgba(219, 213, 246, 0)",
  },
} as const;

export const ALL_AGENTS_STATS_COLORS_MAP = {
  views: {
    label: "Views",
    color: "#EB95D3",
    colorTransparent: "rgba(235, 143, 210, 0)",
  },
  messages: {
    label: "Messages",
    color: "#FDF573",
    colorTransparent: "rgba(235, 143, 210, 0)",
  },
  conversations: {
    label: "Conversations",
    color: "#DCD6F6",
    colorTransparent: "rgba(219, 213, 246, 0)",
  },
  likes: {
    label: "Likes",
    color: "#B1DFF0",
    colorTransparent: "rgba(177, 223, 240, 0)",
  },
} as const;

export const SINGLE_AGENT_ENGAGEMENT_STATS_COLORS_MAP = {
  views: {
    label: "Views",
    color: "#EB95D3",
    colorTransparent: "rgba(235, 143, 210, 0)",
  },
  messages: {
    label: "Messages",
    color: "#FDF573",
    colorTransparent: "rgba(235, 143, 210, 0)",
  },
  conversations: {
    label: "Conversations",
    color: "#DCD6F6",
    colorTransparent: "rgba(219, 213, 246, 0)",
  },
  likes: {
    label: "Likes",
    color: "#B1DFF0",
    colorTransparent: "rgba(177, 223, 240, 0)",
  },
} as const;

export const getAgentOverviewRowId = (agentId: string) => `agent-row-${agentId}`;
