import { FakeSelectItem, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import type { ExternalIntegrations } from "@/types/user";
import { useState } from "react";
import PopupController from "./PopupController";

type Props = {
  isEmailDisabled: boolean | undefined;
  changeDisableEmail: (value: boolean) => void;
  emailVendor: string | undefined;
  changeEmailVendor: (value: string) => void;
  gmailIntegrations: ExternalIntegrations["gmail"];
  refetchUser: () => void;
};

const gmailLoginUrl = import.meta.env.VITE_GMAIL_LOGIN_URL as string;

export const EmailDropdown = (props: Props) => {
  const userToken = localStorage.getItem("token");

  const gmailIntegrations = props.gmailIntegrations || [];
  const [value, setValue] = useState(props.isEmailDisabled ? "no-email" : props.emailVendor || "AGENTX");

  const onChange = (newValue: string) => {
    if (newValue === "ignore-me") {
      return;
    }

    setValue(newValue);
    if (newValue === "no-email") {
      props.changeDisableEmail(true);
    } else {
      props.changeDisableEmail(false);
      props.changeEmailVendor(newValue === "AGENTX" ? "" : newValue);
    }
  };

  return (
    <Select onValueChange={onChange} value={value}>
      <SelectTrigger id="emailModel" className="h-8 w-[auto]">
        <SelectValue placeholder="Pick an option"></SelectValue>
      </SelectTrigger>
      <SelectContent>
        {gmailIntegrations.map(integration => (
          <SelectItem key={integration.email} value={integration.email} className="justify-start">
            <div className="flex items-center justify-between gap-4 text-xs">{integration.email}</div>
          </SelectItem>
        ))}
        <SelectItem key="agentx" value="AGENTX" className="justify-start">
          <div className="flex items-center justify-between gap-4 text-xs">AgentX - hello@agentx.so</div>
        </SelectItem>
        <SelectItem key="no-email" value="no-email" className="justify-start">
          <div className="flex items-center justify-between gap-4 text-xs text-gray-500">Do not send emails</div>
        </SelectItem>
        <FakeSelectItem>
          <PopupController
            className="m-2"
            popupUrl={gmailLoginUrl + "?token=" + userToken}
            onSuccess={props.refetchUser}
          >
            <div className="flex items-center justify-between gap-4 rounded border-2 border-gray-300 px-10 py-2 text-xs font-bold text-primary-500">
              + Connect a new Gmail account
            </div>
          </PopupController>
        </FakeSelectItem>
      </SelectContent>
    </Select>
  );
};
