// src/fb-sdk-init.js
window.fbAsyncInit = function () {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, no-undef
  FB.init({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    appId: import.meta.env.VITE_META_APP_ID, // Access the environment variable
    cookie: true, // enable cookies
    xfbml: true, // parse social plugins on this page
    version: "v21.0",
  });
};

(function (d, s, id) {
  var js,
    fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) {
    return;
  }
  js = d.createElement(s);
  js.id = id;
  js.src = "https://connect.facebook.net/en_US/sdk.js";
  fjs.parentNode.insertBefore(js, fjs);
})(document, "script", "facebook-jssdk");
