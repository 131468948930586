import type { FullAgent } from "@/types/agent";
import { useSendChatMessage } from "../hooks/useSendChatMessage";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { useUpdateNavConversationHistory } from "@/data/queryUpdates/useUpdateNavConversationHistory";
import { useIsGuestUser } from "@/hooks/useIsGuestUser";
import { useLoginDialogContext } from "@/contexts/LoginDialogContext/useLoginDialogContext";

type PredefinedQuestionsProps = {
  agent: FullAgent;
  disabled?: boolean;
};

export const PredefinedQuestions = ({ agent, disabled }: PredefinedQuestionsProps) => {
  const { isGuestUser } = useIsGuestUser();
  const { showLoginDialog } = useLoginDialogContext();
  const { updateNavConversationHistory } = useUpdateNavConversationHistory();

  const { sendMessage } = useSendChatMessage();
  const { isPreview } = useChatContext();

  const questions = agent.definedActions?.predefined;

  if (!questions?.length) {
    return null;
  }

  const handleSendMessage = (message: string) => {
    if (isGuestUser) {
      showLoginDialog();
      return;
    }
    void sendMessage({
      messageText: message,
      onSendMessageCallback: () => {
        if (!isPreview) {
          updateNavConversationHistory({ agents: [agent] });
        }
      },
      agents: [agent],
    });
  };

  return (
    <div className="mx-1 ml-[50px] mt-0 flex cursor-pointer flex-wrap px-4 py-1 text-xs">
      {questions.map(({ question }) => (
        <button
          key={question}
          disabled={disabled}
          onClick={() => handleSendMessage(question)}
          className="my-0 mr-2 rounded-light border border-solid border-cloud-dark bg-white p-2 transition duration-200 ease-in-out hover:bg-cloud-dark"
        >
          {question}
        </button>
      ))}
    </div>
  );
};
