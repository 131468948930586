import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
  DialogDescription,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Icons } from "@/components/ui/icons";
import { SocialMediaShare } from "@/components/SocialMediaShare";
import { useShareConversation } from "@/data/mutations/useShareConversation";

type ShareChatHistoryConversationButtonDialogProps = {
  conversationId: string;
  iconButton?: boolean;
};
export const ShareChatHistoryConversationButtonDialog = ({
  conversationId,
  iconButton,
}: ShareChatHistoryConversationButtonDialogProps) => {
  const { mutate: shareConversation } = useShareConversation();
  const shareLink = `${import.meta.env.VITE_BASE_FRONTEND_URL || "localhost:3001"}/share/conversation/${conversationId}`;

  const onShareClick = () => {
    shareConversation({ conversationId });
  };

  return (
    <Dialog>
      <Button
        size="md"
        variant={iconButton ? "ghost" : "tertiary"}
        className={`${iconButton ? "p-0.5" : "px-4 py-2 "}`}
        onClick={onShareClick}
      >
        <DialogTrigger className="flex items-center">
          <Icons.Share className={iconButton ? "size-5" : "mr-3 size-4"} />
          {!iconButton && "Share conversation"}
        </DialogTrigger>
      </Button>
      <DialogContent variant="small">
        <DialogHeader>Share Conversation</DialogHeader>
        <DialogDescription className="flex w-full flex-col items-center gap-6">
          <SocialMediaShare shareLink={shareLink} />
        </DialogDescription>

        <DialogFooter>
          <DialogClose asChild>
            <Button variant="tertiary" size="md">
              Cancel
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
