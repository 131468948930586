import { HybridTooltipPopover } from "@/components/HybridTooltipPopover";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogFooter } from "@/components/ui/dialog";
import { Icons } from "@/components/ui/icons";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import type { WhatsAppAgent } from "@/types/agent";
import {
  getWhatsAppAgentMessageTemplates,
  createWhatsAppAgentMessageTemplates,
  deleteWhatsAppAgentMessageTemplates,
  WhatsAppTemplateCategory,
  Locales,
} from "@/data/mutations/external-agents/useCreateWhatsAppAgent";
import type {
  WhatsAppMessageTemplate,
  CreateTemplateProp,
} from "@/data/mutations/external-agents/useCreateWhatsAppAgent";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { WhatsAppTemplateButtonDropdown } from "./WhatsAppTemplateButtonDropdown";
import { Controller } from "react-hook-form";

type WhatsAppDialogProps = {
  isDialogOpen: boolean;
  setShowDialog: (value: boolean) => void;
  agentData: WhatsAppAgent | undefined;
};

type WhatsAppPhoneButton = {
  type: "PHONE_NUMBER";
  text: string;
  phone_number: string;
};

type WhatsAppUrlButton = {
  type: "URL";
  text: string;
  url: string;
};

type WhatsAppButtonComponent = WhatsAppPhoneButton | WhatsAppUrlButton;

export const WhatsAppDialog = ({ agentData, isDialogOpen, setShowDialog }: WhatsAppDialogProps) => {
  const [wabaTemplates, setWabaTemplates] = useState<WhatsAppMessageTemplate[]>([]);
  const [selectedLocale, setSelectedLocale] = useState("en");
  const [newTemplateName, setNewTemplateName] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("MARKETING");
  const [headerText, setHeaderText] = useState("");
  const [bodyText, setBodyText] = useState("");
  const [footerText, setFooterText] = useState("");
  const [buttonComponents, setButtonComponents] = useState<WhatsAppButtonComponent[]>([]);
  const [newButtonType, setNewButtonType] = useState<"PHONE_NUMBER" | "URL">("URL");
  const [newButtonText, setNewButtonText] = useState("");
  const [newButtonValue, setNewButtonValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isDeletingId, setIsDeletingId] = useState("");
  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
    async function getTemplate() {
      if (isDialogOpen && agentData && agentData.platformAttributes.phoneNumberId) {
        const templateData = await getWhatsAppAgentMessageTemplates(agentData.platformAttributes.phoneNumberId);
        setWabaTemplates(templateData.data);
        setIsLoading(false);
      }
    }
    void getTemplate();
  }, [isDialogOpen, agentData]);

  const validateTemplateName = (value: string): boolean => {
    // Regular expression: must contain only lowercase letters and underscores, no spaces
    const regex = /^[a-z0-9_]+$/;
    return regex.test(value);
  };

  const createTemplate = async () => {
    setIsCreating(true);
    const headerComponent = {
      type: "HEADER",
      format: "TEXT",
      text: headerText,
    };
    const bodyComponent = {
      type: "BODY",
      text: bodyText,
    };
    const footerComponent = {
      type: "FOOTER",
      text: footerText,
    };

    const allComponents = [];
    if (headerText) {
      allComponents.push(headerComponent);
    }
    if (bodyText && newTemplateName) {
      allComponents.push(bodyComponent);
    } else {
      toast.error("Body text is required.");
      return;
    }
    if (!validateTemplateName(newTemplateName)) {
      toast.error("Template name must contain only lowercase letters and underscores, no spaces.");
      return;
    }
    if (footerText) {
      allComponents.push(footerComponent);
    }
    if (buttonComponents.length > 0) {
      allComponents.push({
        type: "BUTTONS",
        buttons: buttonComponents,
      });
    }

    const createTemplateData = {
      name: newTemplateName,
      category: "MARKETING",
      allow_category_change: false,
      language: selectedLocale,
      components: allComponents,
    } as CreateTemplateProp;
    if (agentData?.platformAttributes.phoneNumberId) {
      const createTemplateResponse = await createWhatsAppAgentMessageTemplates(
        agentData.platformAttributes.phoneNumberId,
        createTemplateData
      );
      //   add the newly created template to the list
      if (createTemplateResponse) {
        const newTemplate: WhatsAppMessageTemplate = {
          name: newTemplateName,
          status: createTemplateResponse.status,
          id: createTemplateResponse.id,
        };
        setWabaTemplates([...wabaTemplates, newTemplate]);
        toast.success(`Template ${newTemplateName} created successfully.`);
      } else {
        toast.error(`Failed to create template ${newTemplateName}!`);
      }
    }
    setIsCreating(false);
  };

  const deleteTemplate = async (templateId: string, templateName: string) => {
    setIsDeletingId(templateId);
    if (agentData?.platformAttributes.phoneNumberId) {
      const payload = { templateId, templateName };
      const deleteRes = await deleteWhatsAppAgentMessageTemplates(agentData.platformAttributes.phoneNumberId, payload);
      if (deleteRes.status === 200) {
        toast.success(`Template ${templateName} deleted successfully.`);
        // Remove the deleted template from the list
        const updatedTemplates = wabaTemplates.filter(template => template.id !== templateId);
        setWabaTemplates(updatedTemplates);
      } else {
        toast.error(`Failed to delete template ${templateName}!`);
      }
    }
    setIsDeletingId("");
  };

  const addButtonComponent = () => {
    if (newButtonText && newButtonValue) {
      const newButton: WhatsAppButtonComponent =
        newButtonType === "PHONE_NUMBER"
          ? { type: "PHONE_NUMBER", text: newButtonText, phone_number: newButtonValue }
          : { type: "URL", text: newButtonText, url: newButtonValue };
      setButtonComponents([...buttonComponents, newButton]);
      setNewButtonText("");
      setNewButtonValue("");
    } else {
      toast.error("Both button text and value are required.");
    }
  };

  const checkIcon = <Icons.Check className="text-green-400" />;
  const pendingIcon = <Icons.Info className="text-orange-400" />;
  const failedIcon = <Icons.StopCircle className="text-red-400" />;

  const WhatsAppMessageBubble = ({ isSent = false }: { isSent: boolean }) => {
    return (
      <div
        className={`relative my-4 max-w-[75%] rounded-xl px-3 py-1 ${
          isSent
            ? "self-end rounded-br-none bg-[#DCF8C6]"
            : "self-start rounded-bl-none border border-gray-300 bg-white"
        }`}
      >
        <div className="flex flex-col gap-1">
          <h4 className="text-base">{headerText}</h4>
          <p className="text-sm">{bodyText || "Template body text here"}</p>
          <p className="text-sm font-light">{footerText || "Template footer text here"}</p>
          <span className="absolute bottom-0 mt-1 self-end text-xs text-gray-500">01:23</span>
          {buttonComponents && (
            <div className="divide-y divide-solid">
              <div />
              {buttonComponents.map((button, idx) => (
                <div
                  key={`buttonComponent-${idx}`}
                  className="flex items-center justify-center gap-2 py-2 text-blue-500"
                >
                  {button.type === "PHONE_NUMBER" ? (
                    <Icons.Microphone className="size-5" />
                  ) : (
                    <Icons.Share className="size-5" />
                  )}
                  {button.text}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setShowDialog}>
      <DialogContent variant="custom" className="min-h-min w-full max-w-[100vw] gap-0 md:max-w-[70vw]">
        <DialogHeader>WhatsApp message template settings (Beta)</DialogHeader>
        {isLoading ? (
          <LoadingSpinner className="flex size-full items-center justify-center" />
        ) : (
          <div className="grid h-[80vh] min-h-[80vh] min-w-[70vw] grid-flow-col grid-cols-7 grid-rows-8 pb-5">
            <div className="col-span-2 row-span-8 flex flex-col gap-4 border-r border-gray-300">
              <div className="border-b border-gray-300 p-4">
                <h4>{agentData?.platformAttributes.wabaName}</h4>
              </div>
              {wabaTemplates.map((template, idx) =>
                isDeletingId === template.id ? (
                  <LoadingSpinner
                    key={`wabaTemplateDeleteLoading${idx}`}
                    className="group relative flex gap-1 break-all px-4"
                  />
                ) : (
                  <div className="group relative flex gap-1 break-all px-4" key={`wabaTemplate${idx}`}>
                    <Label htmlFor="whatsAppTemplateName">{template.name}</Label>
                    {template.status.toString() === "APPROVED" && (
                      <HybridTooltipPopover triggerIcon={checkIcon}>
                        <p>This template is approved.</p>
                      </HybridTooltipPopover>
                    )}
                    {template.status.toString() === "PENDING" && (
                      <HybridTooltipPopover triggerIcon={pendingIcon}>
                        <p>This template is undergoing a review process by Meta team.</p>
                      </HybridTooltipPopover>
                    )}
                    {template.status.toString() === "REJECTED" && (
                      <HybridTooltipPopover triggerIcon={failedIcon}>
                        <p>This template is rejected by Meta.</p>
                      </HybridTooltipPopover>
                    )}
                    <Button
                      variant="tertiary"
                      onClick={async () => {
                        await deleteTemplate(template.id, template.name);
                      }}
                      className="absolute right-3 hidden rounded-full p-1 group-hover:block"
                    >
                      <Icons.Trash className="size-4" />
                    </Button>
                  </div>
                )
              )}
            </div>
            <div className="col-span-5 row-span-2 border-b border-gray-300 px-5 py-5">
              <div className="rounded-lg bg-[#EBE5DE] p-1 px-5">
                <WhatsAppMessageBubble isSent={false} />
              </div>
            </div>
            <div className="col-span-5 row-span-6 mb-14 flex flex-col gap-2 overflow-auto p-5">
              <div className="flex flex-col gap-1">
                <Label htmlFor="whatsAppTemplateName">Name</Label>
                <Input
                  id="whatsAppTemplateName"
                  placeholder="Template Name"
                  value={newTemplateName}
                  onChange={e => setNewTemplateName(e.target.value)}
                />
                <span className="text-xs font-medium text-neutral-400">
                  Must contain only lowercase letters and underscores, no spaces.
                </span>
              </div>
              <div className="flex flex-col gap-1">
                <Label htmlFor="whatsAppTemplateCategory">Category</Label>
                <select
                  id="whatsAppTemplateCategory"
                  className="form-select rounded-lg border border-gray-300 p-2"
                  value={selectedCategory}
                  onChange={e => {
                    setSelectedCategory(e.target.value);
                  }}
                >
                  <option value="AUTHENTICATION">{WhatsAppTemplateCategory.AUTHENTICATION}</option>
                  <option value="MARKETING">{WhatsAppTemplateCategory.MARKETING}</option>
                  <option value="UTILITY">{WhatsAppTemplateCategory.UTILITY}</option>
                </select>
              </div>
              <div className="flex flex-col gap-2">
                <Label htmlFor="whatsAppTemplateLanguage">Language</Label>
                <select
                  className="form-select rounded-lg border border-gray-300 p-2"
                  id="locale-select"
                  value={selectedLocale}
                  onChange={e => {
                    setSelectedLocale(e.target.value);
                  }}
                >
                  {Object.entries(Locales).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col gap-1">
                <Label htmlFor="whatsAppTemplateComponentType">Header text (optional)</Label>
                <Input
                  id="whatsAppTemplateComponentType"
                  placeholder="Template Component Type"
                  value={headerText}
                  onChange={e => setHeaderText(e.target.value)}
                />
              </div>
              <div className="flex flex-col gap-1">
                <Label htmlFor="whatsAppTemplateComponentText">Body text</Label>
                <Textarea
                  id="whatsAppTemplateComponentText"
                  placeholder="Template Component Text"
                  value={bodyText}
                  onChange={e => setBodyText(e.target.value)}
                />
              </div>
              <div className="flex flex-col gap-1">
                <Label htmlFor="whatsAppTemplateComponentFormat">Footer text</Label>
                {/* // 60 characters maximum. */}
                <Input
                  id="whatsAppTemplateComponentText"
                  placeholder="Template Component Text"
                  value={footerText}
                  onChange={e => setFooterText(e.target.value)}
                />
              </div>
              <Label htmlFor="buttonType">Buttons</Label>
              <div className="mt-2 flex flex-wrap gap-3">
                {buttonComponents.map((button, idx) => (
                  <div
                    key={`button${idx}`}
                    className="group relative flex w-fit gap-2 rounded-full border bg-cyan-500 p-2 px-4 py-2 text-sm font-semibold text-white shadow-sm"
                  >
                    <HybridTooltipPopover>
                      <p className="text-xs">
                        {button.type === "PHONE_NUMBER" ? "Phone number: " : "URL: "}
                        {button.type === "PHONE_NUMBER" ? button.phone_number : button.url}
                      </p>
                    </HybridTooltipPopover>
                    <div>{button.text}</div>
                    <Button
                      variant="dark"
                      onClick={() => {
                        const newButtons = buttonComponents.filter((_, i) => i !== idx);
                        setButtonComponents(newButtons);
                      }}
                      className="absolute right-[-8px] top-[-8px] hidden rounded-full p-1 group-hover:block"
                    >
                      <Icons.Trash className="size-4" />
                    </Button>
                  </div>
                ))}

                <Controller
                  name="template-button"
                  render={() => (
                    <WhatsAppTemplateButtonDropdown
                      addButtonComponent={addButtonComponent}
                      setNewButtonType={setNewButtonType}
                      setNewButtonText={setNewButtonText}
                      setNewButtonValue={setNewButtonValue}
                      newButtonType={newButtonType}
                      newButtonText={newButtonText}
                      newButtonValue={newButtonValue}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        )}
        <DialogFooter className="border-t border-gray-300">
          <Button variant="primary" onClick={createTemplate} disabled={!(bodyText && newTemplateName) || isCreating}>
            Create a new template
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
