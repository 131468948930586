import { ROUTES } from "@/constants/routes";
import { useLoginDialogContext } from "@/contexts/LoginDialogContext/useLoginDialogContext";
import { useGetUser } from "@/data/queries/useGetUser";
import { useIsGuestUser } from "@/hooks/useIsGuestUser";
import { cn } from "@/lib/utils";
import { useLocation, useNavigate } from "react-router-dom";

export const CommunityHeader = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user } = useGetUser();

  const { isGuestUser } = useIsGuestUser();
  const { showLoginDialog } = useLoginDialogContext();

  const isMyCommunityProfileActive = pathname.includes(ROUTES.communityProfile(user?._id || ""));

  return (
    <div className="flex w-full flex-col items-center justify-center gap-2.5 rounded-t-[20px] border-b border-neutral-300 px-2.5 pt-8 shadow lg:pt-14">
      <div className="flex gap-10">
        <button
          className={cn("flex pb-1 text-base", {
            "border-b-2 border-violet-600 font-bold": !isMyCommunityProfileActive,
          })}
          onClick={() => navigate(ROUTES.community)}
        >
          Agent Space
        </button>
        <button
          className={cn("flex pb-1 text-base", {
            "border-b-2 border-violet-600 font-bold": isMyCommunityProfileActive,
          })}
          onClick={() => {
            if (isGuestUser) {
              showLoginDialog();
              return;
            }
            navigate(ROUTES.communityProfile(user?._id || ""));
          }}
        >
          My profile
        </button>
      </div>
    </div>
  );
};
