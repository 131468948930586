import { Button } from "@/components/ui/button";
import { Icons } from "@/components/ui/icons";
import { ROUTES } from "@/constants/routes";
import { useNavigate } from "react-router";

export const RegisterSuccess = () => {
  const navigate = useNavigate();

  (window as Window)?.dataLayer?.push({
    event: "registration_completed",
  });

  return (
    <div className="grid place-items-center gap-5 pb-7">
      <p className="flex items-center text-2xl font-bold lg:text-[28px]">
        <Icons.Mails className="mr-4" />
        Email Sent
      </p>
      <p className="text-center text-base font-semibold">
        A verification email has been sent to you. Check spam folder if you do not see it.
      </p>
      <Button variant="primary" className="w-full" size="lg" onClick={() => navigate(ROUTES.login)}>
        Back to Login
      </Button>
    </div>
  );
};
