import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "@/data/initAxios";
import { workspaceUsersKeys } from "@/data/queries/workspace/useGetWorkspaceUsers";
import { userKeys } from "@/data/queries/useGetUser";
import { workspaceDetailsKeys } from "@/data/queries/workspace/useGetWorkspaceDetails";
import { toast } from "react-toastify";

type Props = {
  userId: string;
};

const toggleWorkspaceUserRole = async (props: Props) => {
  const { data } = await restClient.put<unknown>(apiPaths.toggleWorkspaceUserRole, {
    userId: props.userId,
  });
  return data;
};

export const useToggleWorkspaceUserRole = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<unknown, AxiosError<{ message: string }>, Props>({
    mutationFn: toggleWorkspaceUserRole,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: workspaceUsersKeys.all });
      void queryClient.invalidateQueries({ queryKey: userKeys.all });
      void queryClient.invalidateQueries({ queryKey: workspaceDetailsKeys.all });
      toast.success("Role in workspace changed successfully");
    },
  });

  return mutation;
};
