import { useQuery } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import { restClient } from "@/data/initAxios";
import type { AppConfig } from "@/types/config";
import { useGetUser } from "./useGetUser";

export const appConfigKeys = {
  all: ["appConfig"] as const,
};

const getAppConfig = async () => {
  const { data } = await restClient.get<AppConfig>(apiPaths.getConfig);
  return data;
};

export const useGetAppConfig = () => {
  const { user } = useGetUser();

  const query = useQuery({
    queryKey: appConfigKeys.all,
    queryFn: getAppConfig,
    staleTime: Infinity,
    gcTime: Infinity,
    enabled: !!user,
  });

  return query;
};
