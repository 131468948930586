import { Icons } from "@/components/ui/icons";
import { isImageFormatAllowed, MAX_FILE_SIZE_INPUT, MAX_FILE_SIZE_MB } from "@/utils/fileUploads";
import { toast } from "react-toastify";

export const FAQTableRowDropOverlay = ({
  onDragEnd,
  onUpload,
  isExistingFile,
}: {
  onDragEnd: () => void;
  onUpload: (formData: FormData) => void;
  isExistingFile: boolean;
}) => {
  const handleDragOver: React.DragEventHandler<HTMLDivElement> = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave: React.DragEventHandler<HTMLDivElement> = e => {
    e.preventDefault();
    e.stopPropagation();
    onDragEnd();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onDragEnd();

    const files = e.dataTransfer?.files;

    if (files.length > 1 || isExistingFile) {
      toast.error("You can only upload one image.");
      return;
    }

    const file = files[0];

    if (!isImageFormatAllowed(file)) {
      toast.error(`Only images are allowed (${file.name})`);
      return;
    }

    if (file.size > MAX_FILE_SIZE_INPUT) {
      toast.error(`${file.name} exceeds the maximum file size of ${MAX_FILE_SIZE_MB}MB`);
      return;
    }

    const formData = new FormData();
    formData.append("faq-file", file);

    onUpload(formData);
  };

  return (
    <div
      style={{
        background:
          "conic-gradient(from 180deg at 50% 50%, rgba(242, 98, 181, 0.00) 0deg, rgba(95, 197, 255, 0.05) 67.5deg, rgba(255, 172, 137, 0.05) 146.25deg, rgba(129, 85, 255, 0.05) 223.12500715255737deg, rgba(120, 157, 255, 0.05) 285.0000071525574deg, rgba(159, 115, 241, 0.00) 360deg), rgba(255, 255, 255, 0.50)",
      }}
      className="absolute inset-0 z-50 flex items-center justify-center gap-2 p-4 backdrop-blur-md [&>*]:pointer-events-none"
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <Icons.FileUpload className="size-8 min-h-8 min-w-8" />
      <p className="text-sm font-bold">Upload to FAQ</p>
    </div>
  );
};
