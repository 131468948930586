import { CustomerTeamMembers } from "@/pages/Workspace/components/CustomerSpace/CustomerTeamMembers";
import { useGetWorkspaceUsers } from "@/data/queries/workspace/useGetWorkspaceUsers";

export const CustomerDetails = () => {
  const { data: members } = useGetWorkspaceUsers();

  return (
    <>
      <div className="flex flex-col gap-1 border-b-[1.5px] pb-2.5 font-bold">
        AgentX
        <span className="text-xs font-semibold text-neutral-400">
          {members?.length} {members?.length === 1 ? "member" : "members"}
        </span>
      </div>
      <div className="pb-2">
        <CustomerTeamMembers members={members} />
      </div>
    </>
  );
};
