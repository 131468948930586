import { RegisterForm } from "@/components/RegisterForm/RegisterForm";
import { Dialog, DialogContent, DialogFooter, DialogHeader } from "@/components/ui/dialog";
import { Icons } from "@/components/ui/icons";
import type { F1 } from "@/types/types";

type RegisterDialogProps = {
  isDialogOpen: boolean;
  setShowDialog: F1<boolean>;
};

export const RegisterDialog = ({ isDialogOpen, setShowDialog }: RegisterDialogProps) => {
  return (
    <Dialog open={isDialogOpen} onOpenChange={setShowDialog}>
      <DialogContent className="h-dvh w-full overflow-y-scroll md:h-fit md:w-fit md:min-w-[560px]" variant="custom">
        <DialogHeader className="border-none"></DialogHeader>
        <div className="flex h-full w-full flex-1 flex-col items-center gap-2.5 px-16 py-4 xl:max-w-[560px]">
          <Icons.AgentLogo className="mx-auto mb-6 max-h-8 w-[100px] sm:w-44 xl:w-[200px]" />

          <RegisterForm />
        </div>
        <DialogFooter></DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
