import { useMediaQueriesContext } from "@/contexts/MediaQueriesContext/useMediaQueriesContext";
import { singlePrivateAgentWithConfigKeys } from "@/data/queries/useGetSinglePrivateAgentWithConfig";
import { useQueries } from "@tanstack/react-query";
import { useState } from "react";
import { MyAgentDetails } from "../Workspace/MyAgentDetails";
import { Chat } from "@/components/Chat/Chat";
import { cn } from "@/lib/utils";
import BookmarksHistoryPanel from "@/components/BookmarksHistoryPanel/BookmarksHistoryPanel";
import { getSingleConversationAgentWithConfig } from "@/data/queries/useGetSingleConversationAgentWithConfig";
import { useTrackAgentInteractionStats } from "@/hooks/useTrackAgentInteractionStats";
import { useChatContext } from "@/contexts/ChatContext/useChatContext";
import { getSingleConversationAgentGuest } from "@/data/queries/useGetSingleConversationAgentGuest";
import { useIsGuestUser } from "@/hooks/useIsGuestUser";

const NewConversation = () => {
  const { isMobile } = useMediaQueriesContext();
  const { conversationAgentIds: agentIds } = useChatContext();
  const { isGuestUser } = useIsGuestUser();

  const [isAgentDetailsOpen, setIsAgentDetailsOpen] = useState(false);
  const [isMobileBookmarkHistoryPanelOpen, setIsMobileBookmarkHistoryPanelOpen] = useState(false);

  const agentResults = useQueries({
    queries:
      agentIds.map(agentId => ({
        queryKey: singlePrivateAgentWithConfigKeys.id(agentId),
        queryFn: isGuestUser
          ? () => getSingleConversationAgentGuest(agentId)
          : () => getSingleConversationAgentWithConfig(agentId),
        enabled: agentIds.length > 0,
      })) ?? [],
  });

  const [activeAgentIndex, setActiveAgentIndex] = useState(0);

  const isLoading = agentResults.some(r => r.isPending);
  const isError = agentResults.some(r => !!r.error);

  useTrackAgentInteractionStats({ agentIds, enabled: !isGuestUser });

  if (isMobile) {
    return isAgentDetailsOpen ? (
      <MyAgentDetails
        agents={agentResults.map(r => r.data)}
        isLoading={isLoading}
        isAgentDetailsOpen={isAgentDetailsOpen}
        setIsAgentDetailsOpen={setIsAgentDetailsOpen}
        activeAgentIndex={activeAgentIndex}
        setActiveAgentIndex={setActiveAgentIndex}
      />
    ) : (
      <>
        <Chat
          agents={agentResults.map(r => r.data)}
          isLoading={isLoading}
          isError={isError}
          setIsAgentDetailsOpen={setIsAgentDetailsOpen}
          onOpenMobileBookmarksHistoryPanel={() => setIsMobileBookmarkHistoryPanelOpen(true)}
          setActiveAgentIndex={setActiveAgentIndex}
        />
        <div
          className={cn("fixed right-0 top-[72px] z-40 h-full w-full", {
            "-right-full": !isMobileBookmarkHistoryPanelOpen,
          })}
        >
          <BookmarksHistoryPanel onClosePanel={() => setIsMobileBookmarkHistoryPanelOpen(false)} />
        </div>
      </>
    );
  }

  return (
    <>
      <MyAgentDetails
        agents={agentResults.map(r => r.data)}
        isLoading={isLoading}
        activeAgentIndex={activeAgentIndex}
        setActiveAgentIndex={setActiveAgentIndex}
      />
      <Chat
        agents={agentResults.map(r => r.data)}
        isLoading={isLoading}
        isError={isError}
        setActiveAgentIndex={setActiveAgentIndex}
      />
      <BookmarksHistoryPanel />
    </>
  );
};

export default NewConversation;
