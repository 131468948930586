import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPaths } from "@/data/apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "@/data/initAxios";
import { workspaceUsersKeys } from "@/data/queries/workspace/useGetWorkspaceUsers";
import { userKeys } from "@/data/queries/useGetUser";
import { workspaceDetailsKeys } from "@/data/queries/workspace/useGetWorkspaceDetails";
import { toast } from "react-toastify";

const leaveWorkspace = async () => {
  const { data } = await restClient.put<unknown>(apiPaths.leaveWorkspace);
  return data;
};

export const useLeaveWorkspace = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, AxiosError<{ message: string }>>({
    mutationFn: leaveWorkspace,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: workspaceUsersKeys.all });
      void queryClient.invalidateQueries({ queryKey: userKeys.all });
      void queryClient.invalidateQueries({ queryKey: workspaceDetailsKeys.all });
      toast.success("You leave from workspace successfully");
    },
  });
};
