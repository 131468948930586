import { useMutation } from "@tanstack/react-query";
import { apiPaths } from "../apiPaths";
import type { AxiosError } from "axios";
import { restClient } from "../initAxios";

const uploadFAQFile = async (props: FormData) => {
  return (await restClient.post<{ path: string }>(apiPaths.uploadFAQFile, props)).data;
};

export const useUploadFAQFile = () => {
  return useMutation<{ path: string }, AxiosError<{ message: string }>, FormData>({
    mutationFn: uploadFAQFile,
  });
};
