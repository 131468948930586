import { useQuery } from "@tanstack/react-query";
import { restClient } from "../initAxios";
import { apiPaths } from "@/data/apiPaths";

export const singleToolKeys = {
  all: ["singleToolLog"],
  id: (agentId: string, toolId: string) => [...singleToolKeys.all, agentId, toolId],
};

type Props = {
  agentId: string;
  toolId: string;
};

interface Logs {
  urlList: string[];
}

const getSingleToolLogs = async ({ agentId, toolId }: Props) => {
  const { data } = await restClient.get<Logs>(apiPaths.getToolLogs(`${agentId}/${toolId}`));
  return data;
};

export const useGetSingleToolLogs = ({ agentId, toolId }: Props) => {
  const query = useQuery({
    queryKey: singleToolKeys.id(agentId, toolId),
    queryFn: () => getSingleToolLogs({ agentId, toolId }),
    enabled: !!toolId,
  });

  return query;
};
