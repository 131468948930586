import { useGetWorkspaceUsers } from "@/data/queries/workspace/useGetWorkspaceUsers";
import { TeamMembers } from "./TeamMembers";
import { LoadingSpinner } from "@/components/ui/loading-spinner";
import { useGetWorkspaceDetails } from "@/data/queries/workspace/useGetWorkspaceDetails";
import type { TeamStepChildrenProps } from "./Team";
import { TeamDashboardAdminActions } from "@/components/Profiles/Team/TeamDashboardAdminActions";

export const TeamDashboard = ({ setActiveStep }: TeamStepChildrenProps) => {
  const { data: workspaceDetails } = useGetWorkspaceDetails();
  const { data: members, isPending } = useGetWorkspaceUsers();

  const isWorkspaceAdmin = workspaceDetails?.isAdmin;

  return (
    <>
      <div className="mb-5 flex items-center justify-between border-b-[1.5px] pb-5">
        <div className="flex items-baseline gap-x-4">
          <div className="text-lg font-bold">Team</div>
          <div className="flex gap-1">
            {!!members?.length && (
              <>
                <span className="text-sm font-medium text-primary-black">
                  {members?.length} {members?.length === 1 ? "member" : "members"}
                </span>
                {isWorkspaceAdmin && (
                  <span className="text-sm text-neutral-400">
                    (max of {workspaceDetails.customer.subscription.users?.limit})
                  </span>
                )}
              </>
            )}
          </div>
        </div>
        <TeamDashboardAdminActions setActiveStep={setActiveStep} />
      </div>

      {isPending && <LoadingSpinner className="flex size-full items-center justify-center" />}
      {Array.isArray(members) && members.length > 0 && <TeamMembers members={members} />}
    </>
  );
};
