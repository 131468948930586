import { HybridTooltipPopover } from "@/components/HybridTooltipPopover";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import Chip from "@/components/ui/Chip";
import type { Parameter } from "@/types/tools";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import type { SubmitHandler } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { leadGenerationParamRegex } from "@/constants/regexps";
import { Command, CommandGroup, CommandList, CommandItem } from "@/components/ui/Command";
import { Popover, PopoverAnchor, PopoverContent } from "@/components/ui/popover";
import { useCallback, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { LEAD_GEN_PARAMS_SUGGESTIONS, leadGenerationRequiredParams } from "@/constants/leadGenerationContants";
import { useAgentFormContext } from "@/components/AgentForm/hooks/useAgentFormContext";
import { inputDefaultClassName } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { ErrorMessage } from "@/components/ui/ErrorMessage";
import { useGetUser } from "@/data/queries/useGetUser";
import { EmailDropdown } from "@/components/ExternalIntegrations/EmailDropdown";

const leadInfoFormId = "leadInfoForm";

const LeadGenerationContent = () => {
  const agentForm = useAgentFormContext();

  const ref = useRef<HTMLDivElement>(null);
  const { user, refetch } = useGetUser();
  const [isSuggestionsListOpen, setIsSuggestionsListOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    fields: leadInfoFields,
    append: appendLeadInfo,
    remove: removeLeadInfo,
  } = useFieldArray({
    control: agentForm.control,
    name: "leadGeneration.params",
    rules: {
      validate: {
        isNotEmpty: (value: Parameter[]) => {
          const isLeadGenEnabled = agentForm.watch("leadGeneration.enabled");

          const condition = isLeadGenEnabled && value.length === 0;
          return !condition || "At least one parameter is required.";
        },
      },
    },
  });
  const leadParamForm = useForm<{ newLeadParam: string }>({
    mode: "onSubmit",
    defaultValues: {
      newLeadParam: "",
    },
  });

  const handleAddLeadInfoItem = (newParamName: string) => {
    if (leadInfoFields.length >= 10) {
      leadParamForm.setError("newLeadParam", { message: "You can add up to 10 items." });
      return;
    }
    const newParam: Parameter = {
      _id: uuidv4(),
      parameterName: newParamName,
      parameterDescription: `user's ${newParamName}, default empty`,
      required: true,
      type: "string",
    };

    appendLeadInfo(newParam);
    leadParamForm.reset();
  };

  const handleDeleteLeadInfoItem = (index: number) => {
    removeLeadInfo(index);
  };

  const onSubmit: SubmitHandler<{ newLeadParam: string }> = data => {
    handleAddLeadInfoItem(data.newLeadParam);
  };

  const getFilteredSuggestions = useCallback(
    (query: string) => {
      return LEAD_GEN_PARAMS_SUGGESTIONS.filter(framework => {
        const matchesQuery =
          framework.label.trim().toLowerCase().includes(query.trim().toLowerCase()) ||
          framework.value.trim().toLowerCase().includes(query.trim().toLowerCase());

        const doesNotMatchSelectedItems = !leadInfoFields.some(
          param =>
            param.parameterName.trim().toLowerCase() === framework.value.trim().toLowerCase() ||
            param.parameterName.trim().toLowerCase() === framework.label.trim().toLowerCase()
        );

        return matchesQuery && doesNotMatchSelectedItems;
      });
    },
    [leadInfoFields]
  );

  useOnClickOutside(ref, () => setIsSuggestionsListOpen(false));

  return (
    <div className="mt-6 flex flex-col gap-4">
      {agentForm.formState.errors.leadGeneration?.params && (
        <p className="text-[10px] leading-3 text-red-500">
          {agentForm.formState.errors.leadGeneration.params.root?.message}
        </p>
      )}

      <div className="relative flex flex-col items-start gap-2 pb-2">
        <div className="flex items-center gap-1">
          <Label htmlFor="leadTagsInput">Added Lead Info</Label>
          <HybridTooltipPopover heading="Customer info">
            <p>
              Specify the information you want to collect from your customers. You can add more leads to collect such as
              phone number, zip code etc.
            </p>
          </HybridTooltipPopover>
        </div>

        <form
          id={leadInfoFormId}
          onSubmit={leadParamForm.handleSubmit(onSubmit)}
          className="flex w-full flex-col items-start gap-2 lg:flex-row"
        >
          <Controller
            control={leadParamForm.control}
            name="newLeadParam"
            rules={{
              required: "Enter a custom Lead Info name or choose from the list.",
              validate: {
                max10Items: () => leadInfoFields.length < 10 || "You can add up to 10 items.",
                isNotEmpty: value => value.trim() !== "" || "The value cannot be empty.",
                isUnique: value =>
                  !leadInfoFields.some(
                    leadInfoField => leadInfoField.parameterName.trim().toLowerCase() === value.trim().toLowerCase()
                  ) || "The value must be unique.",
              },
              pattern: {
                value: leadGenerationParamRegex,
                message: "Entered value does not match the format (alphanumeric characters and spaces only).",
              },
            }}
            render={({ field: { value, onChange }, formState: { errors } }) => (
              <Command ref={ref}>
                <div
                  onClickCapture={() => inputRef.current?.focus()}
                  className={cn(inputDefaultClassName, "flex flex-wrap gap-1.5 py-1")}
                >
                  {leadInfoFields.length > 0 &&
                    leadInfoFields.map((leadInfoField, index) => {
                      const canDeleteField = !leadGenerationRequiredParams.some(
                        requiredParam => requiredParam.parameterName === leadInfoField.parameterName
                      );

                      return (
                        <Chip
                          key={leadInfoField.parameterName}
                          text={leadInfoField.parameterName}
                          canDelete={canDeleteField}
                          onDeleteClick={() => handleDeleteLeadInfoItem(index)}
                        />
                      );
                    })}
                  <input
                    ref={inputRef}
                    id="leadTagsInput"
                    className="h-7 w-32 grow border-none px-0 focus:border-none focus:ring-0"
                    value={value}
                    onChange={e => {
                      onChange(e);
                      setIsSuggestionsListOpen(true);
                    }}
                    onKeyDown={async e => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        await leadParamForm.handleSubmit(onSubmit)();
                      }
                      if (e.key === "Backspace" && !value.length && leadInfoFields.length > 2) {
                        handleDeleteLeadInfoItem(leadInfoFields.length - 1);
                      }
                    }}
                    onFocus={() => setIsSuggestionsListOpen(true)}
                    // TODO: need to rename all IDs to kebab-case
                    {...(!!errors.newLeadParam && {
                      "aria-invalid": true,
                      "aria-errormessage": "input-error-leadTagsInput",
                    })}
                  />
                </div>
                {errors.newLeadParam && (
                  <ErrorMessage className="mt-1.5" error={errors.newLeadParam} id="input-error-leadTagsInput" />
                )}

                <div className="flex">
                  <Label
                    htmlFor="useGmail"
                    className="mt-2 flex items-center gap-1 text-xs font-medium leading-5 text-neutral-400"
                  >
                    <span>Send confirmation emails using: </span>
                    <Controller
                      control={agentForm.control}
                      name="leadGeneration.disableEmail"
                      render={({ field: { value: disableEmailValue, onChange: onDisableEmailChange } }) => (
                        <Controller
                          control={agentForm.control}
                          name="leadGeneration.emailVendor"
                          render={({ field: { value: emailVendorValue, onChange: onEmailVendorChange } }) => (
                            <EmailDropdown
                              isEmailDisabled={disableEmailValue}
                              changeDisableEmail={value => onDisableEmailChange(value)}
                              emailVendor={emailVendorValue}
                              gmailIntegrations={user?.externalIntegrations.gmail}
                              changeEmailVendor={value => onEmailVendorChange(value)}
                              refetchUser={refetch}
                            />
                          )}
                        />
                      )}
                    />
                  </Label>

                  {/* <Label htmlFor="useGmail" className="mt-2 flex text-xs font-medium leading-5 text-neutral-400">
                    <Controller
                      control={agentForm.control}
                      name="leadGeneration.disableEmail"
                      render={({ field: { value, onChange } }) => (
                        <div className="flex">
                          <Checkbox
                            id="leadgen-email-check"
                            size="sm"
                            checked={!value || false}
                            onCheckedChange={checked => onChange(!checked)}
                            className="mr-2"
                          />
                          {value ? (
                            <span>Check to enable email notification. Currently disabled. </span>
                          ) : (
                            <span>
                              You will receive lead from AgentX email. The confirmation email will be sent to the user
                            </span>
                          )}
                        </div>
                      )}
                    />
                    {!agentForm.watch("leadGeneration.disableEmail") && (
                      <div>
                        {user?.externalIntegrations.gmail ? (
                          <Controller
                            control={agentForm.control}
                            {...agentForm.register("leadGeneration.emailVendor")}
                            render={({ field: { value, onChange } }) =>
                              !value ? (
                                <span className="text-neutral-400">
                                  <span className="font-bold"> from AgentX.{"  "}</span>
                                  <span className="cursor-pointer underline" onClick={() => onChange("GMAIL")}>
                                    (Change to your Gmail)
                                  </span>
                                </span>
                              ) : (
                                <span className="text-neutral-400">
                                  <span className="font-bold">
                                    from {user?.externalIntegrations.gmail?.email}
                                    {"  "}
                                  </span>
                                  <span className="cursor-pointer underline" onClick={() => onChange("")}>
                                    (Change back to AgentX)
                                  </span>
                                </span>
                              )
                            }
                          />
                        ) : (
                          <span className="pl-1 text-neutral-400">
                            <span>(from AgentX, or </span>
                            <PopupController popupUrl={gmailLoginUrl + "?token=" + userToken} onSuccess={refetch}>
                              <span className="cursor-pointer underline">use with your Gmail</span>
                            </PopupController>
                            )
                          </span>
                        )}
                      </div>
                    )}
                  </Label> */}
                </div>

                <Popover open={isSuggestionsListOpen && getFilteredSuggestions(value)?.length > 0}>
                  <PopoverAnchor />
                  <PopoverContent
                    side="bottom"
                    sideOffset={4}
                    className="border-none bg-transparent p-0 shadow-none popover-content-width-same-as-its-trigger"
                    autoFocus={false}
                    onOpenAutoFocus={event => {
                      event.preventDefault();
                    }}
                  >
                    <CommandGroup className="w-full">
                      <CommandList>
                        {getFilteredSuggestions(value).map(suggestion => (
                          <CommandItem
                            key={suggestion.value}
                            value={suggestion.value}
                            onSelect={currentValue => {
                              handleAddLeadInfoItem(currentValue);
                              setIsSuggestionsListOpen(false);
                            }}
                          >
                            {suggestion.label}
                          </CommandItem>
                        ))}
                      </CommandList>
                    </CommandGroup>
                  </PopoverContent>
                </Popover>
              </Command>
            )}
          />

          <Button type="submit" className="h-[38px] w-full sm:w-fit">
            Add info
          </Button>
        </form>
      </div>
    </div>
  );
};

export default LeadGenerationContent;
