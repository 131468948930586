import { Button } from "@/components/ui/button";
import { Icons } from "@/components/ui/icons";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { LeaveWorkspaceMemberDialog } from "@/components/dialogs/TeamDialogs/LeaveWorkspaceMemberDialog";
import type { Dispatch, SetStateAction } from "react";
import { useGetWorkspaceDetails } from "@/data/queries/workspace/useGetWorkspaceDetails";

type TeamDashboardAdminActionsProps = { setActiveStep: Dispatch<SetStateAction<"teamDashboard" | "inviteTeamMember">> };

export const TeamDashboardAdminActions = ({ setActiveStep }: TeamDashboardAdminActionsProps) => {
  const { data: workspaceDetails } = useGetWorkspaceDetails();

  const isWorkspaceAdmin = workspaceDetails?.isAdmin;

  if (!isWorkspaceAdmin) {
    return null;
  }

  return (
    <div className="flex items-center gap-3">
      <Button className="gap-2" onClick={() => setActiveStep("inviteTeamMember")}>
        <Icons.Plus className="size-4" />
        <span className="sr-only sm:not-sr-only">Invite member</span>
      </Button>
      {isWorkspaceAdmin && workspaceDetails.customer.administrators.length !== 1 && (
        <DropdownMenu modal={false}>
          <DropdownMenuTrigger onClick={e => e.stopPropagation()} asChild>
            <Button
              size="custom"
              variant="tertiary"
              roundedType="rounded"
              className="h-10 w-10 min-w-7 border-none hover:bg-neutral-100 active:bg-neutral-300"
            >
              <Icons.Dots2 className="h-6 w-6 rotate-90 text-black" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start">
            <DropdownMenuItem
              className="flex items-center gap-2 text-xs"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <LeaveWorkspaceMemberDialog />
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </div>
  );
};
