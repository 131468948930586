import { useContext } from "react";
import { LoginDialogContext } from "./LoginDialogContext";

export const useLoginDialogContext = () => {
  const context = useContext(LoginDialogContext);

  if (context) {
    return context;
  }

  throw new Error("Use this hook in LoginDialogContext scope");
};
