import { useCallback, useEffect, useState } from "react";
import { ChatContext } from "./ChatContext";
import type { ChatAgent, Conversation } from "@/types/conversation";
import { useQueryClient } from "@tanstack/react-query";
import type { Agent } from "@/types/agent";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { createConversation, createGroupConversation } from "@/data/conversation";
import { conversationMessagesKeys } from "@/data/queries/useGetConversationMessages";
import { chatHistoryWithAgentsKeys } from "@/data/queries/useGetChatHistoryWithAgents";
import { agentConversationsKeys } from "@/data/queries/useGetAgentConversations";
import useIsChatWithPagination from "@/components/Chat/hooks/useIsChatWithPagination";
import {
  agentPreviewConversationsKeys,
  useGetAgentPreviewConversations,
} from "@/data/queries/useGetAgentPreviewConversations";
import { ROUTES } from "@/constants/routes";
import { LocalStorageHelpers } from "@/utils/LocalStorageHelpers";
import { useGetSingleConversationDetails } from "@/data/queries/useGetSingleConversationDetails";
import { agentsGroupConversationsKeys } from "@/data/queries/useGetAgentsGroupConversations";
import { ChatFileUploadStatus, type UploadChatFileResponse } from "@/data/mutations/useUploadFileToConversation";

type ChartProviderProps = {
  children: React.ReactNode;
};

export const ChatProvider = ({ children }: ChartProviderProps) => {
  const queryClient = useQueryClient();
  const withPagination = useIsChatWithPagination();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();

  const { id: urlAgentId, conversationId: urlConversationId } = useParams<{
    id?: Agent["_id"];
    conversationId?: Conversation["_id"];
  }>();

  const isPreview = pathname.includes(ROUTES.editAgent("")) || pathname.includes(ROUTES.createAgent);
  const isChatPath = isPreview || pathname.includes(ROUTES.conversationRoot);

  const [conversationId, setConversationId] = useState<Conversation["_id"] | null>(null);

  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [isMessageError, setIsMessageError] = useState(false);
  const [isAppLoading, setIsAppLoading] = useState(true);
  const [files, setFiles] = useState<UploadChatFileResponse[]>([]);
  const [responseRequestId, setResponseRequestId] = useState<string | null>(null);

  const [messageRecipients, setMessageRecipients] = useState<ChatAgent[]>([]);

  const { data: previewConversations } = useGetAgentPreviewConversations({
    agentId: urlAgentId || "",
    enabled: isPreview && !!urlAgentId,
  });

  const { data: conversationDetails } = useGetSingleConversationDetails({
    conversationId: conversationId!,
    enabled: !!conversationId,
  });

  const isFileUploading = files.some(file => file.status === ChatFileUploadStatus.LOADING);

  const isGroupChat = conversationDetails
    ? conversationDetails.bots.length > 1
    : searchParams.getAll("agent").length > 1;

  const conversationAgentIds = conversationDetails
    ? conversationDetails.bots.map(agent => agent._id)
    : searchParams.getAll("agent");

  const previewConversationId = previewConversations?.[0]?._id;

  const setProviderState = useCallback(() => {
    if (urlConversationId) {
      setConversationId(urlConversationId);
    } else if (isPreview) {
      setConversationId(previewConversationId ?? null);
    }
  }, [isPreview, previewConversationId, urlConversationId]);

  const resetProviderState = () => {
    setConversationId(null);
    setIsSendingMessage(false);
    setIsMessageError(false);
  };

  useEffect(() => {
    setProviderState();

    return () => {
      resetProviderState();
    };
  }, [setProviderState, isChatPath]);

  const createNewConversation = async (agentIds: Agent["_id"][]): Promise<Conversation["_id"] | undefined> => {
    if (!agentIds.length) {
      throw new Error("AgentId is not defined");
    }

    try {
      await queryClient.cancelQueries({ queryKey: conversationMessagesKeys.all });

      const isGroupConversation = agentIds.length > 1;

      let newConversation: Conversation;

      if (isGroupConversation) {
        newConversation = await createGroupConversation(agentIds);
      } else {
        newConversation = await createConversation(agentIds[0], { isPreview });
      }

      if (isPreview) {
        await queryClient.refetchQueries({ queryKey: agentPreviewConversationsKeys.id(agentIds[0]) });
        void queryClient.invalidateQueries({
          queryKey: conversationMessagesKeys.id({ conversationId: newConversation._id }, withPagination),
        });
      } else {
        LocalStorageHelpers.removeAgentsLocalStorageConversation(agentIds);
        LocalStorageHelpers.saveAgentsLocalStorageConversation(agentIds, newConversation._id);

        setConversationId(newConversation._id);
        void queryClient.invalidateQueries({ queryKey: chatHistoryWithAgentsKeys.all });
        void queryClient.invalidateQueries({ queryKey: agentConversationsKeys.all });
        void queryClient.invalidateQueries({ queryKey: agentsGroupConversationsKeys.ids(agentIds) });
      }

      return newConversation._id;
    } catch (error) {
      setIsMessageError(true);
      console.error(error);
    } finally {
      setIsAppLoading(false);
    }
  };

  const value = {
    agentId: urlAgentId,
    conversationId,
    setConversationId,
    isSendingMessage,
    setIsSendingMessage,
    isAppLoading,
    setIsAppLoading,
    createNewConversation,
    isMessageError,
    setIsMessageError,
    isPreview,
    messageRecipients,
    setMessageRecipients,
    isGroupChat,
    conversationAgentIds,
    conversationDetails,
    files,
    setFiles,
    isFileUploading,
    responseRequestId,
    setResponseRequestId,
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};
