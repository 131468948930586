import { useAgentFormContext } from "@/components/AgentForm/hooks/useAgentFormContext";
import { Avatar } from "@/components/Avatar";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { StyleIconSize, type FullAgent } from "@/types/agent";
import { Controller, type UseFormReturn } from "react-hook-form";

type BubbleDisplayProps = {
  form: UseFormReturn<FullAgent["publish"]>;
};

export const BubbleDisplay = ({ form }: BubbleDisplayProps) => {
  const { control } = form;

  const agentForm = useAgentFormContext();
  const { watch } = agentForm;
  const avatar = watch("avatar");

  return (
    <div>
      <Label className="mb-2 text-neutral-400">Bubble Display</Label>
      <Controller
        control={control}
        name="chatStyle.iconSize"
        render={({ field: { value, onChange } }) => (
          <RadioGroup
            onValueChange={onChange}
            defaultValue={value}
            className="grid grid-cols-3 place-items-stretch gap-4 rounded-md bg-neutral-50 px-6 py-4"
          >
            <label className="flex cursor-pointer flex-col items-center justify-end gap-2 rounded-md p-2 hover:bg-primary-50">
              <Avatar src={avatar} size="full" className="size-12 rounded-[40px_40px_4px_40px]" />
              <span className="flex items-center gap-2">
                <RadioGroupItem value={StyleIconSize.Small} />
                Small
              </span>
            </label>
            <label className="flex cursor-pointer flex-col items-center justify-end gap-2 rounded-md p-2 hover:bg-primary-50">
              <Avatar src={avatar} size="full" className="size-16 rounded-[40px_40px_4px_40px]" />
              <span className="flex items-center gap-2">
                <RadioGroupItem value={StyleIconSize.Medium} />
                Medium
              </span>
            </label>
            <label className="flex cursor-pointer flex-col items-center justify-center gap-2 rounded-md p-2 hover:bg-primary-50">
              <Avatar src={avatar} size="full" className="size-20 rounded-[40px_40px_4px_40px]" />
              <span className="flex items-center gap-2">
                <RadioGroupItem value={StyleIconSize.Large} />
                Large
              </span>
            </label>
          </RadioGroup>
        )}
      />
    </div>
  );
};
