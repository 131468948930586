import { useNavigationProfileContext } from "@/contexts/NavigationProfileContext/useNavigationProfileContext";
import { Icons } from "@/components/ui/icons";
import { cn } from "@/lib/utils";
import type { TypeFromConstObject } from "@/types/types";
import { useGetUser } from "@/data/queries/useGetUser";
import { SubscriptionTiers } from "@/types/subscriptions";
import { useGetWorkspaceDetails } from "@/data/queries/workspace/useGetWorkspaceDetails";

export const NavigationProfileSections = {
  MY_ACCOUNT: "My Account",
  AGENTS: "Agents",
  TEAM: "Team",
  BILLING: "Billing",
  ACCOUNT_USAGE: "Account Usage",
} as const;

export type NavigationProfileSection = TypeFromConstObject<typeof NavigationProfileSections>;

const iconsMap = {
  [NavigationProfileSections.MY_ACCOUNT]: Icons.UserBlack,
  [NavigationProfileSections.AGENTS]: Icons.Bot,
  [NavigationProfileSections.TEAM]: Icons.Team,
  [NavigationProfileSections.BILLING]: Icons.Dollar,
  [NavigationProfileSections.ACCOUNT_USAGE]: Icons.Database,
} as const;

export const NavigationProfileSection = () => {
  const { navigationProfileOpen, setNavigationProfileOpen } = useNavigationProfileContext();
  const { data: workspaceDetails } = useGetWorkspaceDetails();
  const { user } = useGetUser();
  const isEnterpriseTier = user?.subscription.tier === SubscriptionTiers.ENTERPRISE;
  const isFreeTier = user?.subscription.tier === SubscriptionTiers.FREE;

  return (
    <div className="flex w-full flex-col sm:gap-2">
      {Object.values(NavigationProfileSections).map(section => {
        if (
          (section === NavigationProfileSections.TEAM && (isFreeTier || !user?.customer)) ||
          (section === NavigationProfileSections.BILLING && isEnterpriseTier && !workspaceDetails?.isAdmin) ||
          (section === NavigationProfileSections.ACCOUNT_USAGE && isEnterpriseTier && !workspaceDetails?.isAdmin)
        ) {
          return null;
        }
        const IconComponent = iconsMap[section];

        return (
          <button
            key={section}
            className={cn(
              "flex w-full items-center rounded-md border-gray-200 px-2 py-4 text-sm font-medium sm:w-40 sm:border-none sm:p-2  lg:w-52 [&:not(:last-child)]:border-b",
              navigationProfileOpen.section === section
                ? "bg-primary-100 font-bold outline-none"
                : "sm:hover:bg-primary-200 sm:hover:font-semibold"
            )}
            onClick={() => {
              setNavigationProfileOpen(prev => ({ ...prev, section }));
            }}
            autoFocus={navigationProfileOpen.section === section}
          >
            <IconComponent />
            <span className="ml-3 lg:ml-4">{section}</span>
            <Icons.ChevronRight className="ml-auto size-6 sm:hidden" />
          </button>
        );
      })}
    </div>
  );
};
